/**
 * Altar - by Abalance
 *
 * This file handles the rendering of one tutorial element
 *
 * @author Valentin Schneeberger
 * @since 14.06.2023
 */

import React from 'react';
import Image from 'react-bootstrap/Image'
import Col from 'react-bootstrap/Col';


class tutoElement extends React.Component {

    handleShowDialog = () => {
        this.setState({ isOpen: !this.state.isOpen })
        this.props.handler(this)
    };

    render() {
        const imgClassName = this.props.name ? 'tuto-image-' + this.props.name : ''
        var valReturn = (
            <Col lg={6} sm={12} className="tuto-column">
                <center>
                    <fieldset>
                        <Image
                            fluid
                            className={"tuto-image " + imgClassName}
                            src={this.props.image}
                            alt={this.props.alt}
                        />



                    </fieldset>
                </center>

            </Col>
        )

        return valReturn
    }
}



export default tutoElement;
