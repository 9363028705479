/**
 * Altar - by Abalance
 *
 * This file handles the account page.
 */

import Account from "../components/account";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import React from 'react';



export default function cards() {
    const { t } = useTranslation();
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('page_account')} - Altar</title>
            </Helmet>
            <Account />
        </>
    );
};