

import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import g6 from '../img/melgraphics/characters.png';
import button_discord from '../img/melgraphics/boutton_discord.png';

const { useState, useEffect } = React;



class Counter extends React.Component {

    constructor(props) {
        super(props)

        let { total, days, hours, minutes, seconds }
            = this.getTimeRemaining(this.getDeadTime());

        this.state = {
            timer: total,
            days: days,
            hours, hours,
            minutes, minutes,
            seconds, seconds,
            display: total > 0
        }

        this.clearTimer(this.getDeadTime())


        //this.timer = this.clearTimer(this.startTimer(this.getDeadTime()))

    }

    getTimeRemaining = (e) => {

        const total = Date.parse(e) - Date.parse(new Date());

        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);

        const days = Math.floor((total / 1000 / 60 / 60 / 24));

        return {
            total, days, hours, minutes, seconds
        };
    }

    startTimer = (e) => {
        let { total, days, hours, minutes, seconds }
            = this.getTimeRemaining(e);

        if (total >= 0) {

            // update the timer
            // check if less than 10 then we need to
            // add '0' at the beginning of the variable
            this.setState({
                timer: total,
                days: days,
                hours, hours,
                minutes, minutes,
                seconds, seconds,
                display: true
            })

        } else {
            this.stopHandler();
            this.setState({
                display: false
            })
        }
    }

    clearTimer = (e) => {

        // If you adjust it you should also need to
        // adjust the Endtime formula we are about
        // to code next   
        this.startTimer(e);

        // If you try to remove this line the
        // updating of timer Variable will be
        // after 1000ms or 1sec
        this.state.id = setInterval(() => {
            this.startTimer(e);
        }, 1000)

    }

    stopHandler = () => {
        clearInterval(this.state.id)
    }

    getDeadTime = () => {
        let deadline = this.props.date;

        // This is where you need to adjust if
        // you entend to add more time
        deadline.setSeconds(deadline.getSeconds());
        return deadline;
    }

    render() {
        //<span className="time">{hours}</span> : <span className="time">{minutes}</span> : <span className="time">{seconds}</span>
        const classname = "timer_container " + (this.state.display ? "" : "timer_container_not")

        /*
  <Container fluid>
                        <Row>
                            <h1>Sortie de Altar dans </h1>
                        </Row>

                        <Row className="timer">
                            <Col xs={12} sm={6} md={3}>
                                {this.state.days > 0 ? <span className="time"> {this.state.days}  jours </span> : ""}
                            </Col>
                            <Col xs={12} sm={6} md={3}>
                                {(this.state.hours > 0 || this.state.days > 0) ? <span className="time"> {this.state.hours} heures  </span> : ""}
                            </Col>
                            <Col xs={12} sm={6} md={3}>
                                {(this.state.minutes > 0 || this.state.hours > 0 || this.state.days > 0) ? <span className="time"> {this.state.minutes} minutes </span> : ""}
                            </Col>
                            <Col xs={12} sm={6} md={3}>
                                <span className="time"> {this.state.seconds} secondes </span>
                            </Col>
                        </Row>

                    </Container>
        */


        const displayDay = this.state.days > 0 ? "display_time" : "display_time_not"
        const displayHours = (this.state.hours > 0 || this.state.days > 0) ? 
            "display_time" : "display_time_not"
        const displayMinutes = (this.state.minutes > 0 || this.state.hours > 0
            || this.state.days > 0) ? "display_time" : "display_time_not"
        
            return (
            <div className={classname}>
                <div className="timer_container_row1 ">

                    <div className="timer_container_row1_col1">
                        <Container fluid>
                            <Row>
                                <h1>Sortie d'Altar dans </h1>
                            </Row>

                            <Row className="timer">
                                <Col xs={12} sm={6} md={3} className={displayDay}>
                                    {this.state.days > 0 ? <span className="time"> {this.state.days}  jours </span> : ""}
                                </Col>
                                <Col xs={12} sm={6} md={3} className={displayHours}>
                                    {(this.state.hours > 0 || this.state.days > 0) ? <span className="time"> {this.state.hours} heures  </span> : ""}
                                </Col>
                                <Col xs={12} sm={6} md={3} className={displayMinutes}>
                                    {(this.state.minutes > 0 || this.state.hours > 0 || this.state.days > 0) ? <span className="time"> {this.state.minutes} minutes </span> : ""}
                                </Col>
                                <Col xs={12} sm={6} md={3}>
                                    <span className="time"> {this.state.seconds} secondes </span>
                                </Col>
                            </Row>

                        </Container>
                        
                        <h2>Patiente en rejoignant notre discord</h2>
                        <a href="https://discord.gg/N8TmZgqeTr" className='discord_btn'>
                            <img src={button_discord} />
                            <h2 className="btn_text">Discord</h2>
                        </a>
                    </div>

                </div >

            </div >

        );
    }
}


export default Counter
