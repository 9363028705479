import React, { createContext, useContext, useState, useEffect } from 'react'

// creating context to use anywhere in app
const DiscountContext = createContext()

// creating custom hook for discounts
export const useDiscount = () => {
    return useContext(DiscountContext)
}

// Creating Provider to wrap the App into so components will be able to access functions
export const DiscountProvider = ({ children }) => {

    // Global State
    const [cart, setCart] = useState([])
    const [database, setDatabase] = useState(null)

    const saveCart = (items) => {
        setCart(items)
    }

    // --------------- Discount Functions -----------------------------
    // -------------------- API Call --------------------------------
    const getDiscounts = () => {
        fetch("/api.php", {
            method: "POST",
            body: JSON.stringify({
                action: "getDiscountInfos"
            }),
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error("Network error");
                }
                return response.json();
            })
            .then(data => {
                setDatabase(data)
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                //throw new Error("Error fetching data");
            });
    }

    const compute_price = (items, database) => {
        var cat_list = list_categories(items, database);

        // list id => saved_money
        var saved_list = {};

        // first look for specific offers (precedence=3)
        for (var prod_id in items) {
            var res = compute_single_price(prod_id, database, cat_list["latest"]);

            // keep track when a reduction has been found
            var base_price = res["base_price"];
            var new_price = res["new_price"];

            if (base_price !== new_price) {
                saved_list[prod_id] = {
                    "base_price": base_price,
                    "new_price": new_price
                };

                // remove from $cat_list if has a reduction
                var category = database["product"][prod_id]["name"];

                if (category === "Base") {
                    delete cat_list["pulse"][prod_id];
                } else {
                    delete cat_list["ext"][prod_id];
                }
            }
        }

        var price = 0;

        // count prices for specific offers
        for (var price_info in saved_list) {
            price += price_info["new_price"];
        }

        delete cat_list["latest"]; // no more needed

        // then look for grouped offers (precedence=2;1)
        price += compute_grouped(cat_list["pulse"], database, "qty_required_pulse");


        const product = Object.values(database)[0].product

        // finally, add basic prices for the rest
        for (var cat in cat_list) {
            cat_list[cat].forEach(id_prod => {

                var prod = Object.values(product)
                var toAdd = 0;


                for (var i = 0; i < prod.length; i++) {
                    var p = prod[i]
                    if (p["id"] == id_prod) {
                        toAdd = parseInt(p["cost"])
                    }

                }
                price += toAdd
            })
        }

        return (price / 100).toFixed(2)
    }

    const list_categories = (items, dc_infos) => {

        //var prod_array = dc_infos["product"];

        var prod_array = Object.values(dc_infos)[0].product


        var pulse_list = new Set();
        var extension_list = new Set();

        var latest_date = 0;
        var latest_list = new Set();

        for (var id in prod_array) {
            id = parseInt(id)

            if (items.includes(id)) {
                if (prod_array[id]["name"] === "Base") {
                    pulse_list.add(id);
                } else {
                    extension_list.add(id);
                }
            }
            if (prod_array[id]["start_date"] > latest_date) {
                latest_date = prod_array[id]["start_date"];
            }
        }

        for (var id in prod_array) {
            if (prod_array[id]["start_date"] == latest_date) {
                latest_list.add(id);
            }
        }

        return {
            "pulse": pulse_list,
            "ext": extension_list,
            "latest": latest_list
        };
    }

    const get_same_prices = (items_of_same_cat, dc_infos) => {
        var products = dc_infos["product"];
        var acc = 0;

        for (var id_prod in items_of_same_cat) {
            acc += products[id_prod]["cost"];
        }

        return acc;
    }

    function compute_grouped(items_of_same_cat, dc_infos, field_name) {
        var count = items_of_same_cat.size;

        var products = Object.values(dc_infos)[0].product
        var discounts = Object.values(dc_infos)[0].discount



        var dc_keys = [];

        var i = 0;
        for (var id in discounts) {
            if (discounts[id][field_name] != null) {
                dc_keys.push(id);
                i++;
            }
        }


        var dc_keys_count = dc_keys.length;
        var price = 0;


        var i = dc_keys_count - 1;
        while (count > 0 && i >= 0) {

            var offer = discounts[dc_keys[i]];

            var qty_required = offer[field_name];

            // found appliable discount
            if (count >= qty_required) {
                price += set_price(
                    get_same_prices(items_of_same_cat, dc_infos),
                    offer.type,
                    offer.dc_value
                );

                count -= qty_required;
                //items_of_same_cat = items_of_same_cat.slice(qty_required);
                items_of_same_cat.delete(qty_required);
            } else {
                i--;
            }
        }

        return price;
    }

    const set_price = (old, type, value) => {
        var res = old;

        switch (type) {
            case "percentage":
                res -= Math.round(old * value / 100);
                break;

            case "amount":
                res -= value;
                break;
        }

        // just in case a discount is fucked up
        if (res < 0) {
            //res = old;
        }

        return res;
    }

    function is_valid_date(start_date, end_date) {
        const currentDate = new Date().toISOString().slice(0, 19).replace("T", " ");

        if (!end_date) {
            const maxTimestamp = new Date(Number.MAX_SAFE_INTEGER);
            end_date = maxTimestamp.toISOString().slice(0, 19).replace("T", " ");
        }

        return start_date <= currentDate && currentDate <= end_date;
    }

    function compute_single_price(id, dc_infos, latest_list) {

        var product = Object.values(dc_infos)[0].product

        var result = {
            "id": id,
            "base_price": product.cost,
            "new_price": product.cost
        };

        // Check if offer_name match :
        //  - in reverse: first if specific/last
        //      - if specific: check id_offer_target
        //  - continue if not matching
        for (var offer in dc_infos.discount) {
            var name = offer.offer_name;
            switch (name) {
                case "specific":
                    if (offer.id_offer_target == id) {
                        if (!is_valid_date(offer.start_date, offer.end_date)) break;

                        result.new_price = set_price(
                            result.base_price,
                            offer.type,
                            offer.dc_value
                        );
                    }
                    break;
                case "last-ext":
                    if (id in latest_list) {
                        if (!is_valid_date(offer.start_date, offer.end_date)) break;

                        result.new_price = set_price(
                            result.base_price,
                            offer.type,
                            offer.dc_value
                        );
                    }
                    break;
            }
        }

        //console.log("Res: " + id + "=" + result["base_price"] + " -> " + result["new_price"] + "\n");
        return result;
    }


    // Call getDiscounts to trigger the data fetch when the component mounts
    useEffect(() => {
        getDiscounts();
    }, []);

    return (
        <DiscountContext.Provider value={{ cart, setCart, saveCart, database, compute_price }}>
            {children}
        </DiscountContext.Provider>
    );
};