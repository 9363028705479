/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the website
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */

// Custom CSS
import './css/index.css';

import './css/previewCards.css';
import './css/description.css';
import './css/tournoi.css'
import './css/buy.css'
import './css/menu.css'
import './css/login.css'
import './css/account_download.css';
import './css/register.css'
import './css/imgGen.css'
import './css/checkout.css'
import './css/validation.css'

// Bootstrap & Icons CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

// Carousel CSS
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import React, { StrictMode, Suspense }  from 'react';
import {createRoot} from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import Layout from "./pages/layout";
import Home from "./pages/home";  
import Cards from "./pages/cards";
import Checkout from "./pages/checkout";
import InsertPseudo from "./pages/insertPseudo";
import Validation from './pages/validation';
import Account from './pages/account';
import FreeDownload from './pages/download'
import TermsOfUse from './pages/termsOfUse';
import NotFound from './pages/notFound'

// Importing DiscountContext Provider to wrap the App
import { DiscountProvider } from './context/DiscountContext';

/* If using React Redux, can use code below */
// import store and provider
// import { store } from './store';
// import { Provider } from 'react-redux';

// Translation module initialization & settings
import './i18n';

export default function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Altar</title>
      </Helmet>
      <Routes>
          <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="cards" element={<Cards />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="insert" element={<InsertPseudo />} />
          <Route path="account" element={<Account />} />
          <Route path="download" element={<FreeDownload />} />
          <Route path="validation" element={<Validation />} />
          <Route path='terms-of-use' element={<TermsOfUse />} />
          
          <Route path="404" element={<NotFound />} />
          <Route path="*" element={<Navigate to="404" replace={true} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}


const container = document.getElementById("app")
const root = createRoot(container);

root.render(
  <StrictMode>
    <Suspense>
        <DiscountProvider>
          <App />
        </DiscountProvider>
    </Suspense>
  </StrictMode>
);

