/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the cards
 *
 * @author Valentin Schneeberger
 * @since 12.06.2023
 */

import React from 'react';
import Image from 'react-bootstrap/Image'

// Translations
// import { t } from 'i18next'
import { withTranslation } from 'react-i18next';

class Product extends React.Component {

  handleRemoveItem = () => {
    // console.log("try remove item")
    this.props.handleRemove(this)
  }

  render() {
    // If Rendered in checkout
    if (this.props.onPaiement) {
      return <Image
        src={this.props.img}
        className={this.props.class}
        alt={'image ' + this.props.title}
        title={this.props.title}
      />
    }

    // If Rendered in Cart
    if (this.props.onBoughtList) {
      const className = 'buy_pulse_image_cart buy_pulse_bought_image_position' + this.props.position
      const crossClassName = 'buy_pulse_cross_image buy_cross_bought_image_position' + this.props.position
      const reversedClassName = this.props.position % 2 === 0 ? ' flex-row-reverse' : '' // placing remove button on the left for even items
      return <div className={`buy_list_background_pulse_container col-6 ${reversedClassName}`}>
        <Image
          src={this.props.img}
          className={className}
          alt={'image ' + this.props.title}
          title={this.props.title}
        />
        <div
          className={crossClassName}
          onClick={this.handleRemoveItem}
        ><i className="bi bi-cart-dash-fill"></i>
        </div>

      </div>
    }


    const className = ('buy_pulse_image buy_pulse_image_position' + this.props.position) + (this.props.isBought ? " buy_pulse_image_bought" : " ")
    return <div className='buy_pulse_container'>

      <Image
        src={this.props.img}
        className={className}
        alt={'image ' + this.props.title}
        title={this.props.title}
      />

    </div>
  }
}



export default withTranslation()(Product);
