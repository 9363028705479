/**
 * Altar - by Abalance
 *
 * This file handles the Error 404 not Found page.
 */
import React from 'react';

import '../css/notFound.css'

import { Helmet } from "react-helmet";
import { Container } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

export default function notFound() {
    const { t } = useTranslation()
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('page_not_found')} - Altar</title>
            </Helmet>
            <section className='notFound_wrapper'>
                <Container className='notFound_container align-middle vh-100 d-flex flex-column justify-content-center'>
                    <h1>{t('not_found_error')}</h1>
                    <h2>{t('page_not_found')}</h2>
                    <img className='m-3' src={require("../img/not_found_void.svg")} alt="error404" />
                    <h4>
                        {t('not_found_text')}{' '}
                        <a className='notFound_link' href='/'>
                            {t('not_found_link')}
                        </a>.
                    </h4>
                </Container>
            </section>

        </>
    );
};