import React from 'react';

import { Image } from 'react-bootstrap';

import { useState } from 'react'
import { redirect } from "react-router-dom";

import crossExit from '../img/melgraphics/croix.png'

// translations module
import { t } from 'i18next';


class Login extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            username: '',
            password: '',
            emptyfields: true,
            wrongCredentials: true,
            loginFail: false
        }
    }

    usernameHandler = (event) => {
        this.setState({
            username: event.target.value,
            loginFail: false
        });
    }

    passwordHandler = (event) => {
        this.setState({
            password: event.target.value,
            loginFail: false
        });
    }

    submitHandler = (event) => {
        event.preventDefault()
        this.loginRequest()
    }

    loginRequest() {
        try {
            fetch('/api.php', {
                method: 'POST',
                body: JSON.stringify({
                    action: 'login',
                    email: this.state.username,
                    password: this.state.password,
                }),
            })
                .then((respose) => {

                    if ( respose.ok ) {
                        return respose.json()
                    }
                    if ( respose.status == 400 ) {
                        this.setState({
                            loginFail: true
                        })
                    } else if ( respose.status == 401 ) {
                        this.setState({
                            loginFail: true
                        })
                    }
                    // throw new Error('error')
                })
                .then((data) => {
                    // console.log(data.status)
                    if (data.status !== undefined && data.status !== null && data.status !== '' && data.status !== 0 ) {
                        this.setState({
                            loginFail: false
                        })
                        localStorage.setItem('email', data.email)
                        localStorage.setItem('token', data.status)
                        this.props.onExit()
                        redirect('/account')
                    } else {
                        this.setState({
                            loginFail: true
                        })
                    }
                })
        } catch (error) {
            if ( error.status == 400 ) {
                this.setState({
                    loginFail: true
                })
            } else if ( error.status == 401 ) {
                this.setState({
                    loginFail: true
                })
            }
        }
    }


    render() {
        const classname = 'login-form ' + this.props.display;
        const { loginFail } = this.state
        
        var loginMessage = null

        if( loginFail === true ) {
            loginMessage = <div class="login_alert alert alert-danger" role="alert">
                { t('login_wrong_credentials') }
            </div>
        } else {
            loginMessage = <div></div>
        }

        return (
            <form className={classname}>
                <div
                    className='login_background'
                ></div>
                <Image 
                    className='login_crossExit'
                    src={crossExit}
                    onClick={this.props.onExit}
                />
                <h2 className='login_text'>{t('menu_login_text')}</h2>
                {loginMessage}
                <div className='login_username_container20'>
                    <label>{t('login_register_email')}</label>
                    <input className='login_fields' type="text" value={this.username} onChange={this.usernameHandler} />
                </div>
                <div className='login_password_container'>
                    <label>{t('login_register_pwd')}</label>
                    <input className='login_fields' type="password" value={this.password} onChange={this.passwordHandler} />

                </div>

                <div className='login_bouton'>
                    <div
                        className='button gradientOrangePink buttonRadius buttonShadows'
                        onClick={this.submitHandler}
                    >{t('menu_login_text')}</div>
                </div>
            </form>
        )
    }
}

export default Login