/**
 * Altar - by Abalance
 *
 * This file handles the account page.
 */

import Cards from "../components/cards";


import React from 'react';

import Validation from "../components/validation";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";


export default function validation() {
    const { t } = useTranslation()
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('page_validation')} - Altar</title>
            </Helmet>
            <Validation />
        </>
    );
};