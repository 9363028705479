import React from 'react';

import { Container } from 'react-bootstrap';

function TermsOfUse() {
    return (
        <Container className='termsOfUse bg-light boxShadows buttonRadius p-4' style={{ 'margin-top': '10vh' }}>
            <h2>Conditions Générales de Vente (CGV)</h2>
            <ol>
                <li>Objet</li>
                <p>Les présentes CGV régissent la vente de produits numériques, notamment les decks de cartes nommés Pulse, par Abalance SA via AltarCG.</p>
                <li>Acceptation des Conditions</li>
                <p>L'achat de produits sur AltarCG implique l'acceptation sans réserve des présentes CGV.</p>
                <li>Identification du Vendeur</li>
                <p>Abalance SA, inscrite au registre du commerce depuis le 15 avril 2020 sous l'IDE CHE- 148.038.616, sise à la rue de la Balance 1, 2000 Neuchâtel en date du 14 août 2023. Email: contact@abalance.ch</p>
                <li>Produits</li>
                <p>AltarCG par Abalance SA propose la vente de decks de cartes évolutifs dont les droits d'impression sont transférés aux joueurs.</p>
                <p>Altar card game est un jeu de cartes s’inspirant librement d’autres card games comme Magic the gathering, Yu Gi Oh ou encore Pokemon Card Game.</p>
                <p>Altar CG d'Abalance SA introduit une nouvelle méthode de distribution de jeux de cartes, transférant le droit d'impression des cartes aux joueur.euse.s. Les joueur.euse.s auront donc la possibilité respectivement le droit d’imprimer leurs cartes eux-mêmes.</p>
                <p>Cette approche est facilitée par un site internet dédié.</p>
                <p>Le jeu se joue en physique et les interfaces digitales sont ici un moyen de décentraliser la production tout en encourageant les joueur.euse.s à se réunir en personne.</p>
                <li>Processus de Commande</li>
                <p>Les différentes étapes techniques conduisant à la conclusion d'un contrat sont clairement indiquées lors de l’achat. Les détails du contrat peuvent être contrôlés et corrigés par l’acheteur avant la conclusion. La confirmation de la commande est envoyée sans délai par courrier électronique.</p>
                <li>Prix et Paiement</li>
                <p>Les prix sont en francs suisses (CHF) et non soumis à la TVA. Le paiement est effectué uniquement par carte bancaire. Tous les suppléments non optionnels sont inclus.</p>
                <li>Livraison</li>
                <p>Livraison immédiate par voie électronique dès réception du paiement du prix de vente par Abalance SA.</p>
                <li>Retours et Remboursements</li>
                <p>Aucun retour, échange ou remboursement n'est possible en raison de la nature numérique des produits. Il n'y a pas de droit de rétractation en Suisse.</p>
                <li>Responsabilité et Limitations</li>
                <p>La responsabilité de Abalance SA est limitée dans tous les cas à la faute grave et au dol. Toute autre responsabilité est expressément exclue. Abalance SA ne saurait être tenue responsable d’un quelconque dommage direct ou indirect ou d’éventuelles pertes financières ou frais sous réserve des dispositions impératives du Code des obligations.</p>
                <p>Dans tous les cas et sous réserve de dispositions impératives, la responsabilité de Abalance SA sera limitée au prix du produit.</p>
                <li>Modifications des CGV</li>
                <p>Abalance SA se réserve le droit d’adapter ses CGV à tout moment. La version des CGV applicable pour le client qui commande est celle valable au moment de la commande et accessible sous le lien <a href="https://www.1.altarcg.com/terms-of-use">https://www.1.altarcg.com/terms-of-use</a>.</p>
                <li>Droit applicable et for</li>
                <p>La validité des présentes CGV ainsi que des relations juridiques entre Abalance SA et le client relève exclusivement du droit suisse à l'exclusion de la Convention des Nations unies sur les contrats de vente internationale de marchandises.</p>
                <p>Le for exclusif pour toute procédure est celui de Neuchâtel, dans le canton de Neuchâtel, Suisse, sous réserve d’un for impératif donné au bénéfice du consommateur.</p>
            </ol>
            <h2>Conditions Générales d'Utilisation (CGU)</h2>
            <ol>
                <li>Objet</li>
                <p>Les présentes CGU régissent l'accès et l'utilisation d'AltarCG (le « Site »).</p>
                <li>Acceptation des CGU</li>
                <p>L'utilisation d'AltarCG implique l'acceptation sans réserve des présentes CGU.</p>
                <li>Utilisation et accessibilité du Site</li>
                <p>Toute utilisation abusive du site est interdite. Les exceptions incluent le reporting de bugs ou d'autres fragilités du système auprès de l'administrateur du site AltarCG de Abalance SA à contact@abalance.ch.</p>
                <p>Abalance SA ne garantit aucune accessibilité spécifique de son Site et peut à tout moment limiter ou réglementer l'accès à son Site.</p>
                <p>Elle peut prendre cette décision en particulier lorsque cette mesure s'avère nécessaire pour des raisons liées à la sécurité de l'exploitation du réseau, au maintien de l'intégrité du réseau (pour éviter des perturbations graves du réseau) ou de données sauvegardées.</p>
                <p>Abalance SA se réserve le droit, à tout moment et de temps à autre, de modifier ou d’interrompre, temporairement ou définitivement, le Site (ou toute partie de celui-ci), avec ou sans préavis.</p>
                <li>Exclusion de garantie</li>
                <p>L’utilisation du Site est fournie « en l’état » sans qu’aucune garantie, expresse ou implicite, ne soit donnée. L’utilisateur utilise le Site à ses risques et périls.</p>
                <p>L’utilisateur du Site comprend et accepte expressément que Abalance SA ne sera pas responsable des dommages directs ou indirects y compris les dommages pour pertes de profits liés au Site.</p>
                <li>Limitation de responsabilité</li>
                <p>La responsabilité de Abalance SA est limitée dans tous les cas à la faute grave et au dol. Toute autre responsabilité est expressément exclue. Abalance SA ne saurait être tenue responsable d’un quelconque dommage direct ou indirect ou d’éventuelles pertes financières ou frais sous réserve des dispositions impératives du Code des obligations.</p>
                <p>Dans tous les cas et sous réserve de dispositions impératives, la responsabilité de Abalance SA sera limitée à un montant de CHF 100.-.</p>
                <li>Politique de Confidentialité</li>
                <p>Abalance SA s'engage à respecter la Loi fédérale sur la protection des données du 1er septembre 2023 et adaptation future de la loi.</p>
                <li>Propriété Intellectuelle</li>
                <p>Les droits de propriété intellectuelle relatifs au Site ainsi que l’agencement des pages du Site et leur contenu, notamment tous les documents, éléments et programmes sont l’exclusive propriété de Abalance SA.</p>
                <li>Informations sur le Site</li>
                <p>Le Site met à disposition des internautes les informations nécessaires pour garantir sa durabilité.</p>
                <li>Modification des CGU</li>
                <p>Abalance SA se réserve le droit d’adapter ses CGU à tout moment. La version des CGU applicable pour le client qui commande est celle valable au moment de la commande et accessible sous le lien <a href="https://www.1.altarcg.com/terms-of-use">https://www.1.altarcg.com/terms-of-use</a>.</p>
                <li>Droit applicable et for</li>
                <p>La validité des présentes CGU ainsi que des relations juridiques entre B2Bitcoin et l’Utilisateur relève exclusivement du droit suisse à l’exclusion de tout droit étranger et de toute convention internationale.</p>
                <p>Le for exclusif pour toute procédure est celui de Neuchâtel, dans le canton de Neuchâtel, Suisse.</p>
            </ol>
        </Container>
    )
}

export default TermsOfUse;