/**
 * Altar - by Abalance
 *
 * This file handles the download page.
 */

import FreeDownload from "../components/download";


import React from 'react';



export default function download() {
    return (
        <FreeDownload />
    );
};