/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the validation after buying something
 *
 * @author Valentin Schneeberger
 * @since 14.06.2023
 */

import React from 'react';
import { Container } from 'react-bootstrap';

import Login from './login';
import ReturnButton from './returnButton';


// Translations Module
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';


class Validation extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            login: 'login_not',
        }
        this.onAccountClick = this.onAccountClick.bind(this)
        this.onLoginExit = this.onLoginExit.bind(this)
    }

    onAccountClick() {
        this.setState({
            login: 'login',
        })
    }
    onLoginExit() {
        this.setState({
            login: 'login_not',
        })
    }

    handleReturn() {
        document.location.href = '/';
    }

    render() {
        return (
            <div className="validation_background">
                <Container>
                    <div className='validation_container32'>
                        <div className='validation_centered gradientOrangePink boxShadows'>
                            <p>{t('validation_message')}</p>
                            <p>{t('validation_email')}<a href='mailto:communication@abalance.ch'>communication@abalance.ch</a></p>
                            <p>{t('validation_seeyou')}</p>
                        </div>
                        <div className='validation_accederImage gradientOrangePink buttonShadows' onClick={this.onAccountClick}>
                            {t('validation_account')}
                        </div>
                        <ReturnButton customClass="validation_retourImage"/>
                        <div className='validation_login'>
                            <Login display={this.state.login} onExit={this.onLoginExit} />
                        </div>
                    </div>
                </Container>
            </div>
        )
    }
}



export default withTranslation()(Validation);
