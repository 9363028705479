/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the cards
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */


import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// Translations Module
import { t } from 'i18next';

const discord = require('../img/icons/discord3.png')
const facebook = require('../img/icons/facebook2.png')
const linkedin = require('../img/icons/linkedin.png')

const twitter = require('../img/icons/twitter.webp')
const instagram = require('../img/icons/instagram2.png')
const youtube = require('../img/icons/youtube2.png')
// const twitch = require('../img/icons/twitch.png')


class Social extends React.Component{
    render(){
        return <Row className='buysquares' >
        <Col md={2} xs={1}>  </Col>
        <Col>
          <div className='followusfluid '>
            <div className='followusfluid2 '>{ t('social_call_to_action') }</div>
            <div className='followusfluid3'>
              <Row>
                <Col md={3}> </Col>
                <Col md={3} xs={12}>
                  <ul className="social-menu">
                    <li><a href="https://discord.gg/N8TmZgqeTr" target="_blank" rel="noopener noreferrer"><i
                    ><img
                        className="social-icons"
                        src={discord}
                        alt=""/></i></a></li>
                    <li><a href="https://www.facebook.com/abalance.ch/" target="_blank" rel="noopener noreferrer"><i
                    ><img
                        className="social-icons"
                        src={facebook} /></i></a></li>
                    <li><a href="https://www.linkedin.com/company/abalance-sa/" target="_blank" rel="noopener noreferrer"><i
                    ><img
                        className="social-icons"
                        src={linkedin} /></i></a></li>

                  </ul>
                </Col>
                <Col md={3} xs={12}>
                  <ul className="social-menu">
                    <li><a href="https://twitter.com/Abalance16/" target="_blank" rel="noopener noreferrer"><i className="fab"><img
                      className="social-icons"
                      src={twitter} /></i></a></li>
                    <li><a href="https://www.instagram.com/abalance_gaming/" target="_blank" rel="noopener noreferrer"><i
                    ><img
                        className="social-icons"
                        src={instagram} /></i></a></li>
                    <li><a href="https://www.youtube.com/channel/UCrQiCzy-DE86cel3P1VnY-Q/featured"
                      target="_blank" rel="noopener noreferrer"><i ><img
                        className="social-icons"
                        src={youtube} /></i></a></li>
                  </ul>
                </Col>
                <Col md={3}> </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col md={2} xs={1}> </Col>
      </Row>
           
    }
}



export default Social