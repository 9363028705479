/**
 * Altar - by Abalance
 *
 * This file handles the main page
 *
 * @author Valentin Schneeberger
 * @since 12.06.2023
 */

// modules
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import YouTube from 'react-youtube';

// Images
import print from '../img/homeactions/print_textless.png';
import cut from '../img/homeactions/cut_textless.png';
import sleeve from '../img/homeactions/sleeve_textless.png';
import play from '../img/homeactions/play_textless.png';

// Components
import Cards from "./cards";
import Accueil from './accueil';
import Description from './description';
import Tournoi from './tournoi';
import Buy from './buy';
import Social from './social';
import GameRules from './gameRules';
import Footer from "./footer";
import Counter from './counter';

// Translations Module
import i18n, { t } from 'i18next';
import { withTranslation } from 'react-i18next';



class Main extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      id: null,
      outDate: new Date("09/21/2023 09:00:00"),

      cards: [],
      discounts: [],
      database: null

    }
    this.clearTimer()
  }

  componentDidMount = () => {
    this.fetchCards()
    i18n.on('languageChanged', this.fetchCards)
  }

  componentWillUnmount = () => {
    i18n.off('languageChanged', this.fetchCards)
}

  // ----------- Timer functions --------------------
  startTimer = (e) => {
    let total = this.getTimeRemaining(e);

    // console.log("timer timer ")

    if (total >= 0) {

      // update the timer
      // check if less than 10 then we need to
      // add '0' at the beginning of the variable
      this.setState({
        timer: true
      })

    } else {
      this.setState({
        timer: false
      })
      this.stopHandler()
    }
  }

  clearTimer = (e) => {

    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next   
    this.startTimer(e);

    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec

    this.state.id = setInterval(() => {
      this.startTimer(e);
    }, 1000)

  }

  getTimeRemaining = (e) => {

    const total = Date.parse(e) - Date.parse(new Date());

    return total
  }

  stopHandler = () => {
    clearInterval(this.state.id)
  }


  // ------------ Cards fetch : Used in pulse Cards and Buy(product preview) ------------------------
  fetchCards = async () => {
    const lng = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').slice(0, 2) : 'en'
    await fetch('/api.php', {
      method: 'POST',
      body: JSON.stringify({
        action: 'getCards',
        lang: lng
      })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Server gave Error:' + response.status);
        } else {
          return response.json()
        }
      })
      .then((data) => {
        if (data) {
          if (data.Cards) {
            // filtering all cards to current language

            // console.log(data.Cards)
            const cardsData = data.Cards.filter(c => c.lang === lng)
            // console.log('fetched cards in lng OK')

            const cardsValues = this.formatCards(cardsData)

            // SORTING CARDS : Grouping by intensity, then sorting alphabetically by english card name 
            const groupedByIntensity = {};

            cardsValues.sort((a, b) => {
              if (a.intensity < b.intensity) {
                return -1;
              } else if (a.intensity > b.intensity) {
                return 1;
              } else {
                return a.en_title.localeCompare(b.en_title, 'en'); // Specify 'en' locale explicitly
              }
            });

            // console.log('cards to be passed in state: ', cardsValues)
            this.setState({ cards: cardsValues })

          } else {
            //set error
          }
        }
      })
      .catch(error => {
        console.error('Products could not be fetched:', error)
        const cards_t = t('cardsInfos', { ns: 'cards', returnObjects: true })
        // this.setProducts(Object.values(pulses_t), 'products')
      })
  }

  formatCards(array) {
    var formatedArray = []

    array.forEach(item => {
      var card = {}
      // console.log('item: ', item)

      // determining where star is in text
      const star1 = item.has_starb == 'true' ? true : false
      const star2 = item.has_special_starb == 'true' ? true : false

      card.key = item.image
      card.title = item.title
      card.en_title = item.en_name
      card.text = [item.en_textb, item.en_text2b]
      card.star1 = star1
      card.star2 = star2
      card.image = item.image
      card.pulse = item.pulse
      card.product = item.product
      card.intensity = item.intensity

      // console.log(card)

      formatedArray.push(card)
    })
    // formatedArray.sort((a, b) => a.title.localeCompare(b.title)); // sorting Cards by title

    return formatedArray
  }

  render() {

    if (this.getTimeRemaining(this.state.outDate) > 0) {
      return (
        <div className="pas_surlignable page_sommaire">
          <div className="totalwrap">
            <Counter date={this.state.outDate} />
          </div>
        </div>
      )
    }

    /*
 <Container fluid className='belowfoldwrp'>

            <Row className='buysquares doit'>
              <Col className="buysquares">
                <div id="" className='previewfluid'>
                  <a href="#cardpreview" className='previewcardslink'>View cards</a>
                </div>
              </Col>
              <Col className="buysquares">
                <a href="#rules"><Image className="d-block mx-auto" src={rules} alt="" /> </a>
              </Col>
            </Row>

           


            <Row className='doit'>

              <Col className="buysquares" md={2} xs={12}>
                <img className="img-fluid" src={print} alt="" />
              </Col>
              <Col className="shevion" md={1} xs={12}>
                  >
              </Col>
              <Col className="buysquares" md={2} xs={12}>
                <img className=" img-fluid" src={cut} alt="" />
              </Col>
              <Col className="shevion" md={1} xs={12}>
                  >
              </Col>
              <Col className="buysquares" md={2} xs={12}>
                <img className="img-fluid " src={sleeve} alt="" />
              </Col>
              <Col className="shevion" md={1} xs={12}>
                    >
              </Col>
              <Col className="buysquares" md={2} xs={12}>
                <img className="img-fluid" src={play} alt="" />
              </Col>
            </Row>

          </Container>

            <Row id="rules">
              <Col className="buysquares">
                <div className='asquare'>Rules in one page</div>
              </Col>
            </Row>
    */

    return (
      <div className="pas_surlignable page_sommaire">
        <div className="totalwrap">

          <Accueil />

          <Description />

          <Container fluid className='Steps'>
            <Row className='idsj'>

              <Col className="buysquares" md={2} sm={5} xs={12}>
                <h3 className=''>{t('steps_print_title')}</h3>
                <img src={print} alt="" width="60%" />
                <h3>{t('steps_print_subtitle')}</h3>
              </Col>
              <Col className="shevion" md={1} sm={1} xs={12}>
                {'>'}
              </Col>
              <Col className="buysquares" md={2} sm={5} xs={12}>
                <h3>{t('steps_cut_title')}</h3>
                <img src={cut} alt="" width="80%" />
                <h3>{t('steps_cut_subtitle')}</h3>
              </Col>
              <Col className="shevion" md={1} sm={1} xs={12}>
                {'>'}
              </Col>
              <Col className="buysquares" md={2} sm={5} xs={12}>
                <h3>{t('steps_sleeve_title')}</h3>
                <img src={sleeve} alt="" width="50%" />
                <h3>{t('steps_sleeve_subtitle')}</h3>
              </Col>
              <Col className="shevion" md={1} sm={1} xs={12}>
                {'>'}
              </Col>
              <Col className="buysquares" md={2} sm={5} xs={12}>
                <h3>{t('steps_play_title')}</h3>
                <img src={play} alt="" width="70%" />
                <h3>{t('steps_play_subtitle')}</h3>
              </Col>
            </Row>
          </Container>

          <div>

            <center>

              <Cards cards={this.state.cards} />

            </center>
          </div>

          <Buy cards={this.state.cards} />

          <Tournoi />

          <Container fluid className='tutorialWrapper'>

            <Row className="videoTutorial py-5" id="videos">
              <center>
                <Col className='videos'>
                  <center>

                    <fieldset>
                      <legend className='link-text'>{t('video_tutorial_title')}</legend>
                      <YouTube videoId="9oqUOI68jrA" className='video_container' />
                    </fieldset>
                  </center>
                </Col>
              </center>
            </Row>

            <Social />

            <GameRules />

          </Container>

          <Footer />
        </div>
      </div >
    );

  }
}

export default withTranslation()(Main)

