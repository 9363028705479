import React from "react";

import ImgDisplay from "./ImgDisplay";

class ImgGenerator extends React.Component {
  constructor(props) {
    super(props);
    const img1 = require('../img/finalCardsSample/en/abadi.png')
    this.state = {
      topText: "",
      randomImg: img1
    };

    this.size = 12;
    this.img_gen_input_error = "img_gen_input_error_no_display";
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    if (value.length < this.size) {
      this.img_gen_input_error = "img_gen_input_error_no_display";
      this.setState({ [name]: value });
    } else {
      this.img_gen_input_error = "img_gen_input_error";
      this.setState({ [name]: value.slice(0, this.size) });
    }  

    this.props.onChangePseudo(event)
      
  };

  //Add local image
  handleUpload = (event) => {
    event.preventDefault();
    const { files } = event.target;
    const uploadFile = URL.createObjectURL(files[0]);
    this.setState({
      randomImg: uploadFile,
    });
  };

  //Add random image
  handleRandom = (event) => {
    event.preventDefault();
    const randNum = Math.floor(Math.random() * this.state.allTextImgs.length);
    const randTextImg = this.state.allTextImgs[randNum].url;
    this.setState({ randomImg: randTextImg });
  };

  render() {
    return (
      <ImgDisplay
        handleChange={this.handleChange}
        handleRandom={this.handleRandom}
        data={this.state}
        size={this.size}
        img_gen_input_error={this.img_gen_input_error}
      />
    );
  }
}

export default ImgGenerator;
