import React from "react";
import { useTranslation } from "react-i18next";
// import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

function BuyButton() {
  const { t } = useTranslation()

  return (
    <a href='/#purchase' className="buttonRadius button gradientBlue buttonShadows">{t('download_cta_buy_button')}</a>
  )
}

export default BuyButton
