import React, { Component } from 'react'
import Row from 'react-bootstrap/Row';

// Components
import TutoElement from './tutoElement';

// Translations Module
import i18next, { t } from 'i18next'
import { withTranslation } from 'react-i18next'

class GameRules extends Component {

    getLocalizedImage = (text) => {
        const lng = localStorage.getItem('i18nextLng') !== undefined ? localStorage.getItem('i18nextLng').slice(0,2) : i18next.resolvedLanguage
        const image = text + '_' + lng
        return image
    }

    render() {
        // Gets translations
        const tutoElements = t('tutoElements', { ns: 'tuto', returnObjects: true })
        // Sort game rules in order in case they are not
        const tutoElems_s = tutoElements.sort((a, b) => a.step - b.step)
        const tuto = []
        if (Object.values(tutoElems_s)[0]['text'] !== undefined) {
            Object.values(tutoElems_s).forEach(tutoElement => {
                // checks if the image is used for all language or path needs to be processed
                const localizedImg = tutoElement.needsTranslation ? this.getLocalizedImage(tutoElement.image) : tutoElement.image
                var img1 = ''
                try {
                    img1 = require('../img/tuto_text/' + localizedImg + '.png')
                }
                catch (e) {
                    // console.log('the image could not be found')
                    console.log(e)
                }

                const regle = (
                    <TutoElement
                        key={tutoElement.name}
                        name={tutoElement.name}
                        image={img1}
                        text={tutoElement.text}
                        id={tutoElement.id}
                        alt={tutoElement.image + '.png'}
                    />
                );
                tuto.push(regle)

            })

        }
        return (
            <Row className='doit gameRules mt-5 py-3'>
                <center>
                    <h2>{t('tutorial_game_rules')}</h2>
                </center>

                {tuto}
            </Row>
        )
    }
}

export default withTranslation('tuto')(GameRules)
