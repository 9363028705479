import React from "react";
import { Image } from "react-bootstrap";


// Translations Module
import { t } from 'i18next';

function ImgDisplay(props) {

  return (
    <div className="img_gen_image_pseudo_container">
      <div className="img_gen_image_container">
        <Image
          src={props.data.randomImg}
          className="img_gen_image"
          alt=""
        />
        <div className="text_container">
          <p className="topText">{props.data.topText}</p>
        </div>
      </div>


      <div className="img_gen_pseudo_container">

        <div className="img_gen_pseudo_input_container img_gen_input_background gradientOrangePink boxShadows">
          <div className="img_gen_input_container"> 
            <p className={props.img_gen_input_error}>{ t('imgGen_warning') } {props.size}  { t('imgGen_chars') }</p>
            <input
              className="img_gen_input"
              type="text"
              name="topText"
              placeholder={ t('imgGen_instruction_nickname') }
              value={props.data.topText}
              onChange={props.handleChange}
            />
          </div>
        </div>

        <div className="img_gen_instruction_container gradientBlue boxShadows">
          <p className="img_gen_insctruction_text">
            { t('imgGen_instruction_text') }
          </p>
        </div>

      </div>


    </div >

  );
}

export default ImgDisplay;
