import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom'

import title from '../img/melgraphics/accueil_logo.png';
import g6 from '../img/melgraphics/characters.png';
import bouton_buy from '../img/melgraphics/boutton_buy.png';

import button_twitch from '../img/melgraphics/boutton_twitch.png';
import button_discord from '../img/melgraphics/boutton_discord.png';

// Translation module
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';

class Accueil extends React.Component {
    render() {

        return (

            <div className="container text-center accueil">

                <Container fluid>
                    <Row>
                        <Col md={6}>
                            <img className="d-block mx-auto" src={g6} alt="" width="100%"
                                height="" />
                        </Col>
                        <Col md={6}>
                            <Row className='position-relative mx-auto'>
                                {/* Altar Logo */}
                                <img className='d-block' src={title} alt="" width="100%" height="100%" />
                                <h3 className="accueil_logo_text">{t('accueil_title')}</h3>
                            </Row>

                            <Row>
                                <div className="purchasewrp">
                                    <div className='topBuyButton'>
                                        <a href="#purchase" className='buy_btn'>
                                            <img src={bouton_buy} />
                                            <h1 className="buy_btn_text btn_text">{t('accueil_button_buy')}</h1>
                                        </a>

                                        <Row>
                                            <Col lg={6}>
                                                <a href="#videos" className='what_am_i_link button gradientOrangePink buttonradius buttonShadows mx-auto'>
                                                    <div className="what_am_i_btn">{t('accueil_button_what_am_i')}</div>
                                                </a>
                                            </Col>
                                            <Col lg={6}>
                                                <Link to="/download" className='freedownload_link button gradientPurpleRed buttonRadius buttonShadows mx-auto'>
                                                    <div className="freedownload_btn">{t('accueil_button_freedownload')}</div>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>

                                <Row className='socials m-auto'>
                                    <Col lg={6}>
                                        <a href="https://discord.gg/N8TmZgqeTr" className='discord_btn'>
                                            <img src={button_discord} />
                                            <h2 className="btn_text">Discord</h2>
                                        </a>
                                    </Col>
                                    <Col lg={6}>
                                        <a href="https://www.twitch.tv/abalance_gaming" className='twitch_btn'>
                                        <img src={button_twitch} />
                                        <h2 className="btn_text">Twitch</h2>
                                        </a>
                                    </Col>
                                </Row>
                            </Row>
                        </Col>
                    </Row>


                </Container>
            </div>
        )
    }

}

export default withTranslation()(Accueil)

