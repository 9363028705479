import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import Image from 'react-bootstrap/Image'

// import right from '../img/melgraphics/droite_univers.png';

import { useTranslation } from 'react-i18next';
import i18n from 'i18next';


function Tournoi() {
    const { t } = useTranslation(['events']);
    const [events, setEvents] = useState([])
    const [mounted, setMounted] = useState(false)

    var lng = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').slice(0, 2) : i18n.resolvedLanguage

    useEffect(() => {
        let mounted = true
        console.clear()
        console.log(mounted)
        if (mounted) {
            console.log(mounted)
            fetchEvents()
        }

        return () => {
            mounted = false
            setMounted(false)
        }
    }, [])

    async function fetchEvents() {
        await fetch('/api.php', {
            method: 'POST',
            body: JSON.stringify({
                action: 'getEvents'
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Server gave Error:' + response.status);
                } else {
                    return response.json()
                }
            })
            .then((data) => {
                if (data) {
                    // console.log(data)
                    if (data.events) {
                        console.log(data.events)
                        // set Events in state
                        setEvents(data.events)
                    } else {
                        //set error
                    }
                }
            })
            .catch(error => {
                console.error('Events could not be fetched:', error)
            })
    }

    function formatDate(strDate) {
        const dateStr = strDate;

        console.log(dateStr)
        // Step 1: Parse the date string into a Date object
        const date = new Date(dateStr);

        // Step 2: Get the user's preferred language (you can do this based on your i18next setup)
        const userLanguage = i18n.resolvedLanguage

        let year = date.toLocaleDateString(userLanguage, { year: "numeric" })
        let month = date.toLocaleDateString(userLanguage, { month: "short" })
        let weekday = date.toLocaleDateString(userLanguage, { weekday: "short" })
        let day = date.toLocaleDateString(userLanguage, { day: "2-digit" })
        let hour = date.toLocaleTimeString(userLanguage, { hour: "2-digit", hour12: false, minute: "2-digit" })

        // Step 3: Format the date according to user language

        let formattedDate = `${weekday} ${day} ${month} ${year} à ${hour}`
        switch (userLanguage.slice(0, 2)) {
            case "en":
                hour = date.toLocaleTimeString(userLanguage, { hour: "2-digit", hour12: true, minute: "2-digit"})
                formattedDate = `${weekday}, ${month} ${day}, ${year} at ${hour}`
                break;
            default:
                break;
        }

        return formattedDate
    }

    return (
        <div className="tournoi_grid">
            <div className='tournoi_container tournoi_image'>
                <Container className='tournoi_text d-flex align-items-center flex-column justify-content-center'>
                    <h1>{t('upcoming_events')}</h1>
                    {events.length > 0 ?
                        (
                            <table className='fw-light fs-5 w-auto d-block overflow-y-auto' style={{ 'height': '250px' }}>
                                <thead><tr><th>{t('events_date')}</th><th>{t('events_event')}</th><th>{t('events_address')}</th></tr></thead>
                                <tbody>
                                    {events.map((event) => {
                                        return (
                                            <tr key={'event_' + event.id}>
                                                <td className='text-start'>{formatDate(event.Date)}</td><td>{lng == 'fr' ? event.fr_name : event.name}</td><td className='text-end'><a href={event.link_location}>{event.Location}</a></td>{/* <td className='text-end'>{event.note && '|'} {event.note}</td> */}
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        )
                        :
                        <div className='fw-light fs-5'>{t('noEvent')}</div>
                    }
                </Container>
            </div>
        </div >
    )
}

export default Tournoi

