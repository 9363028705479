import React from 'react';
// import { wrapGrid } from 'animate-css-grid'

import { Modal, OverlayTrigger, Popover } from 'react-bootstrap';
// import Badge from 'react-bootstrap/Badge';

import Product from './product';
import AltarCard from './card';
// import CardList from './productList';
// import ResponsiveSlider from './slider';

import withDiscount from './HOC/withDiscounts'

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


// Translations
import i18n, { t } from 'i18next';

class Buy extends React.Component {

    constructor(props) {
        super(props)
        this.handleLeft = this.handleLeft.bind(this)
        this.handleRight = this.handleRight.bind(this)
        this.handleToCheckout = this.handleToCheckout.bind(this)
        this.hideAndShowText = this.hideAndShowText.bind(this)

        this.state = {
            products: [], // list of products
            expansions: [],
            selectedProduct: null, // selectedProduct in state
            selectedExpansion: null,
            currentPdtPosition: 0,
            currentExpPosition: 0,
            bought: [], // list of bought items
            touchStartX: null,
            touchEndX: null,
            isOpen: false, // show more button on mobile
            modalShow: false,
            price: 0
        };
    }

    componentDidMount = () => {
        this.fetchTranslatedProducts()
        i18n.on('languageChanged', this.fetchTranslatedProducts)
    }

    componentWillUnmount = () => {
        i18n.off('languageChanged', this.fetchTranslatedProducts)
    }

    fetchTranslatedProducts = () => {
        const products_t = t('pulsesInfos', { ns: 'cards', returnObjects: true });
        var lng = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').slice(0, 2) : 'en'
        fetch('/api.php', {
            method: 'POST',
            body: JSON.stringify({
                action: 'getAvailableProduct',
                lang: lng
            })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Server gave Error:' + response.status);
                } else {
                    return response.json()
                }
            })
            .then((data) => {
                if (data) {
                    // console.log(data)
                    if (data.AvailableProduct) {
                        // console.log('fetch data: ', data.AvailableProduct)

                        // filtering all products to base decks
                        const productsData = data.AvailableProduct.filter(p => p.ename === 'Base')

                        const productsValues = this.formatProducts(productsData)
                        // this.setState({ fetchedProducts: productsValues }) // per use with global Cart



                        // test dummy expansions

                        // drawing base products selection component
                        this.setProducts(productsData, 'products', true)

                        // filtering all products to base expansions
                        const expansionsData = data.AvailableProduct.filter(e => e.ename !== 'Base')

                        const expansionsDummy = [
                            {
                                id: 7,
                                image: 'death.png',
                                p_en_name: 'Court of Fallen Souls',
                                p_fr_name: 'Cour des Âmes Déchus',
                                p_fr_description: 'Complétez votre arsenal de la mort avec des puissantes carte des Âmes Déchues',
                                p_en_description: 'Complete your death arsenal with powerful Fallen Souls cards',
                                cost: 1400,
                                ename: 'First',
                                startdate: '2023-09-13'
                            },
                            {
                                id: 8,
                                image: 'order.png',
                                p_fr_name: 'Tribunal Archerande',
                                p_en_name: 'Archerande Tribunal',
                                p_fr_description: "Enrichissez votre collection de cartes du célèbre Tribunal Archerande",
                                p_en_description: 'Expand your collection of cards from the famous Archerande Tribunal',
                                cost: 1400,
                                ename: 'First',
                                startdate: '2023-09-13'
                            },
                            {
                                id: 9,
                                image: 'lies.png',
                                p_fr_name: 'Légion des Ombres Éternelles',
                                p_en_name: 'Legion of Eternal Shadows',
                                p_fr_description: 'Rejoignez la Légion des Ombres Éternelles et renforcez votre deck avec des cartes redoutables.',
                                p_en_description: 'Join the Legion of Eternal Shadows and strengthen your deck with formidable cards.',
                                cost: 1400,
                                ename: 'First',
                                startdate: '2023-09-13'
                            },
                            {
                                id: 10,
                                image: 'life.png',
                                p_fr_name: 'Requiem des Guérisseurs Octecite',
                                p_en_name: 'Requiem of Octecite Healers',
                                p_fr_description: 'Soignez vos blessures et renforcez vos alliés avec les cartes du Requiem des Guérisseurs Octecite, une extension dédiée à la guérison et à la vitalité',
                                p_en_description: 'Heal your wounds and empower your allies with cards from the Requiem of Octecite Healers, an expansion dedicated to healing and vitality',
                                cost: 1400,
                                ename: 'First',
                                startdate: '2023-09-13'
                            },
                            {
                                id: 11,
                                image: 'truth.png',
                                p_fr_name: 'Codex des Secrets Révélés',
                                p_en_name: 'Codex of Revealed Secrets',
                                p_fr_description: 'Découvrez la vérité cachée et révelez les cartes de votre adversaires avec les cartes du Codex des Secrets Révélés, une extension dédiée à la connaissance et à la révélation',
                                p_en_description: 'Uncover hidden truths and reveal your opponent\'s cards with cards from the Codex of Revealed Secrets, an expansion dedicated to knowledge and revelation',
                                cost: 1400,
                                ename: 'First',
                                startdate: '2023-09-13'
                            },
                            {
                                id: 12,
                                image: 'order.png',
                                p_fr_name: 'Tribunal Archerande',
                                p_en_name: 'Archerande Tribunal',
                                p_fr_description: "Enrichissez votre collection de cartes du célèbre Tribunal Archerande",
                                p_en_description: 'Expand your collection of cards from the famous Archerande Tribunal',
                                cost: 1400,
                                ename: 'First',
                                startdate: '2023-09-13'
                            },
                            {
                                id: 13,
                                image: 'lies.png',
                                p_fr_name: 'Légion des Ombres Éternelles',
                                p_en_name: 'Legion of Eternal Shadows',
                                p_fr_description: 'Rejoignez la Légion des Ombres Éternelles et renforcez votre deck avec des cartes redoutables.',
                                p_en_description: 'Join the Legion of Eternal Shadows and strengthen your deck with formidable cards.',
                                cost: 1400,
                                ename: 'First',
                                startdate: '2023-09-13'
                            },
                            {
                                id: 14,
                                image: 'life.png',
                                p_fr_name: 'Requiem des Guérisseurs Octecite',
                                p_en_name: 'Requiem of Octecite Healers',
                                p_fr_description: 'Soignez vos blessures et renforcez vos alliés avec les cartes du Requiem des Guérisseurs Octecite, une extension dédiée à la guérison et à la vitalité',
                                p_en_description: 'Heal your wounds and empower your allies with cards from the Requiem of Octecite Healers, an expansion dedicated to healing and vitality',
                                cost: 1400,
                                ename: 'First',
                                startdate: '2023-09-13'
                            },
                            {
                                id: 15,
                                image: 'truth.png',
                                p_fr_name: 'Codex des Secrets Révélés',
                                p_en_name: 'Codex of Revealed Secrets',
                                p_fr_description: 'Découvrez la vérité cachée et révelez les cartes de votre adversaires avec les cartes du Codex des Secrets Révélés, une extension dédiée à la connaissance et à la révélation',
                                p_en_description: 'Uncover hidden truths and reveal your opponent\'s cards with cards from the Codex of Revealed Secrets, an expansion dedicated to knowledge and revelation',
                                cost: 1400,
                                ename: 'First',
                                startdate: '2023-09-13'
                            }
                        ]

                        // this.setProducts(expansionsDummy, 'expansions', true)

                        // console.log(this.state.expansions)

                        if (expansionsData.length > 0) {
                            const expansionValues = this.formatProducts(expansionsData)
                            this.setProducts(expansionsData, 'expansions', true)
                            // this.setState({ expansions: expansionsData }) // per use with global cart
                        }

                    } else {
                        //set error
                    }
                }
            })
            .catch(error => {
                console.error('Products could not be fetched:', error)
                this.setProducts(Object.values(products_t), 'products')
            })

    }

    formatProducts(array) {
        const lng = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en'
        var formatedArray = []
        var product = {}

        // setting dynamic property name for potential future language implementation - provided the entry exists in the database and is fetched
        const p_lng_name = `p_${lng}_name`
        const p_lng_description = `p_${lng}_description`

        array.forEach(item => {
            const pname = item[p_lng_name]
            const pdescription = item[p_lng_description]
            const ptype = item.ename
            const pcost = (item.cost / 100)

            product.id = item.id
            product.image = item.image
            product.title = pname
            product.description = pdescription
            product.cost = pcost
            product.type = ptype
            product.startdate = item.start_date

            formatedArray.push(product)
        })

        return formatedArray
    }

    handleModal(value) {
        // console.log('New value of modal is:', value)

        this.setState({ modalShow: value })
    }

    /* setProducts arguments :
        array : array used for proceesing (either from fetch or local translations)
        isFetched : used to determine which object properties are used for render - render local data if error
        component : used to get last position the component was in before update
    */
    setProducts(array, component, isFectched = false) {
        var lng = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').slice(0, 2) : 'en'
        var products = []
        var expansions = []

        const { currentPdtPosition, currentExpPosition } = this.state

        let productsValues = array
        let midArrayPos = Math.floor(array.length / 2)
        let basePosition = midArrayPos !== 0 ? -(midArrayPos) : midArrayPos;

        let currentPosition = 0
        switch (component) {
            case 'products':
                // console.log('Unprocessed products are: ', array)
                currentPosition = currentPdtPosition
                break;
            case 'expansions':
                // console.log('Unprocessed products are: ', array)
                currentPosition = currentExpPosition
                break;
            default:
                break;
        }
        let selectLastPos = midArrayPos + 1 + currentPosition

        // console.log('selectLastPos: ', selectLastPos)

        const sortedProducts = this.handleProductPosition(productsValues, currentPosition)

        sortedProducts.map(productItem => {
            var pname = productItem.title
            var pdescription = productItem.text
            const img1 = require('../img/pulses/' + productItem.image);
            let isBought = false
            if (this.state.bought !== null && this.state.bought.find(boughtItem => boughtItem.props.id === productItem.id)) {
                isBought = true
            }

            var ptype = ''
            var pcost = 24.90
            if (isFectched) {
                const p_lng_name = `p_${lng}_name`
                const p_lng_description = `p_${lng}_description`
                pname = productItem[p_lng_name]
                pdescription = productItem[p_lng_description]
                ptype = ' - ' + productItem.ename
                pcost = (productItem.cost / 100)
            }

            const selectedClass = basePosition === 0 && 'product_selected'
            // console.log('array length: ' + sortedProducts.length + ' - maxRight:' + midArrayPos)
            const hiddenItemClass = basePosition > midArrayPos - 1 && 'hidden'
            const productClasses = selectedClass + ' ' + hiddenItemClass

            const product = (
                <Product
                    key={'product_' + productItem.id}
                    class={productClasses}
                    img={img1}
                    position={basePosition}
                    title={pname + ptype}
                    text={pdescription}
                    cost={pcost}
                    isBought={isBought}
                    id={productItem.id}
                    handleRemove={(e) => this.handleRemove(e, component)}
                />
            )
            switch (component) {
                case 'products':
                    products.push(product)
                    break;
                case 'expansions':
                    expansions.push(product)
                    break;
                default:
                    break;
            }
            basePosition++
        });

        switch (component) {
            case 'products':
                const selectedProduct = products.find(productItem => productItem.props.id === selectLastPos);
                this.setState({
                    products: products,
                    selectedProduct: selectedProduct
                });
                break;
            case 'expansions':
                const selectedExpansion = expansions.find(productItem => productItem.props.position === selectLastPos);
                this.setState({
                    expansions: expansions,
                    selectedExpansion: selectedExpansion
                });
                break;
            default:
                break;
        }


        // console.log('selectedExpansion is: ', this.state.selectedExpansion)

    }

    handleProductPosition = (array, lastPos) => {
        const firstPart = array.slice(lastPos);
        const secondPart = array.slice(0, lastPos);

        return firstPart.concat(secondPart);
    }

    updatePrice = (newCart) => {
        const { database, onComputePrice } = this.props
        return onComputePrice(newCart, database)
    }
    handleRemove = (elem, component) => {
        const { products, expansions } = this.state
        const { setCart, database } = this.props

        var array = []
        var products2 = []
        var newBought = []
        var selected = []
        var newCart = []
        var count = 0
        var newPrice = 0

        switch (component) {
            case 'products':
                array = products
                break;
            case 'expansions':
                array = expansions
                break;
            default:
                break;
        }

        array.forEach(p => {
            var nBought = p.props.id === elem.props.id ? false : p.props.isBought
            var p2 = <Product
                img={p.props.img}
                position={p.props.position}
                title={p.props.title}
                text={p.props.text}
                isBought={nBought}
                onBoughtList={false}
                id={p.props.id}
                handleRemove={(e) => this.handleRemove(e, component)}
            />
            if (nBought) {
                var p3 = <Product
                    img={p.props.img}
                    position={count}
                    title={p.props.title}
                    text={p.props.text}
                    isBought={nBought}
                    onBoughtList={true}
                    id={p.props.id}
                    handleRemove={(e) => this.handleRemove(e, component)}
                />
                count += 1
                newBought.push(p3)

                // updating Cart
                newCart.push(p.props.id)
            }

            products2.push(p2)
            if (p.props.position == 0) {
                selected = p2
            }
        })

        // elem !== undefined && console.log(elem.props)

        // updating global Cart state
        setCart(newCart)

        // updating price
        newPrice = database !== null ? this.updatePrice(newCart) : 0

        this.setState(prevState => ({
            [component === 'products' ? 'products' : 'expansions']: products2,
            [component === 'products' ? 'selectedProduct' : 'selectedExpansion']: selected,
            bought: newBought,
            price: newPrice
        }));

    }

    // refactored logic for remove from cart for simlicity purposes. Not yet Used
    removeFromCart = (productId) => {
        const { bought } = this.state

        // const itemToRemove = bought.find(cartItem => cartItem.props.id === productId)
        const newCart = bought.filter(cartItems => cartItems.props.id != productId)

        this.setState({ bought: newCart })
        // localStorage.setItem('bought', newCart)
    }

    handleRight(event, component) {
        const { products, expansions, currentPdtPosition, currentExpPosition } = this.state

        var array = []
        var currentPosition = 0
        var products2 = []
        switch (component) {
            case 'products':
                currentPosition = currentPdtPosition
                products2 = [...products]
                array = products
                break;
            case 'expansions':
                currentPosition = currentExpPosition
                products2 = [...expansions]
                array = expansions
                break;
            default:
                break;
        }
        var select

        let mostRightPos = Math.ceil(products.length / 2)  // +2 for 6
        let mostLeftPos = -Math.floor(products.length / 2) // -3 for 6

        // determines current position to keep when lang is changed
        var currentPos = currentPosition === mostRightPos - 1 ? mostLeftPos : currentPosition + 1


        array.forEach((p, index) => {
            var oldPosition = p.props.position

            if (oldPosition === mostLeftPos) {
                oldPosition = mostRightPos
            }
            if (oldPosition === 1) {
                select = products2[index]

            }
            products2[index] = (
                <Product
                    img={p.props.img}
                    position={oldPosition - 1}
                    title={p.props.title}
                    text={p.props.text}
                    isBought={p.props.isBought}
                    id={p.props.id}
                    handleRemove={(e) => this.handleRemove(e, component)}
                />
            )
        })


        this.setState({
            [component === 'products' ? 'products' : 'expansions']: products2,
            [component === 'products' ? 'selectedProduct' : 'selectedExpansion']: select,
            [component === 'products' ? 'currentPdtPosition' : 'selectedExpansion']: currentPos
        });


    }
    handleLeft(event, component) {
        const { products, expansions, currentPdtPosition, currentExpPosition } = this.state

        var array = []
        var currentPosition = 0
        var products2 = []
        var select;
        switch (component) {
            case 'products':
                currentPosition = currentPdtPosition
                products2 = [...products]
                array = products
                break;
            case 'expansions':
                currentPosition = currentExpPosition
                products2 = [...expansions]
                array = expansions
                break;
            default:
                break;
        }

        let mostRightPos = Math.ceil(this.state.products.length / 2)
        let mostLeftPos = -Math.floor(this.state.products.length / 2)

        // determines current position to keep when lang is changed
        var currentPos = currentPosition === mostLeftPos ? mostRightPos - 1 : currentPosition - 1

        array.forEach((p, index) => {
            var oldPosition = p.props.position
            if (oldPosition === mostRightPos) {
                oldPosition = mostLeftPos
            }
            if (oldPosition === -1) {
                select = products2[index]
            }
            products2[index] = (
                <Product
                    img={p.props.img}
                    position={oldPosition + 1}
                    title={p.props.title}
                    text={p.props.text}
                    isBought={p.props.isBought}
                    id={p.props.id}
                    handleRemove={(e) => this.handleRemove(e, component)}
                />
            )
        })


        this.setState({
            [component === 'products' ? 'products' : 'expansions']: products2,
            [component === 'products' ? 'selectedProduct' : 'selectedExpansion']: select,
            [component === 'products' ? 'currentPdtPosition' : 'selectedExpansion']: currentPos
        });

    }

    // updated function for addToCart any product; base or upcoming expansion. Currently used for expansions
    addToCart = (productId) => {
        const { expansions, bought } = this.state
        const isProductInCart = bought.find(cartItem => cartItem.props.id === productId) // to be changed with cart state with only values and no JSX

        if (!isProductInCart) {
            const newCartItem = expansions.find(exp => exp.id === productId)

            // temporary code below for current Cart Logic. Later, only state will be changed
            const img1 = require('../img/pulses/' + newCartItem.image)
            const newBought = <Product
                key={newCartItem.id}
                img={img1}
                position={bought.length}
                title={newCartItem.title}
                text={newCartItem.description}
                isBought={true}
                onBoughtList={true}
                id={newCartItem.id}
                handleRemove={(e) => this.handleRemove(e, 'products')}
            />
            //END of temporary code


            this.setState(prevState => ({
                bought: [...prevState.bought, newBought]
            }))
        }
    }

    // TO BE REVIEWED FOR ANIMATION
    handleAddToCart = (component) => {
        const { selectedProduct, selectedExpansion } = this.state
        const { setCart, database } = this.props
        var selectedItem = {}
        switch (component) {
            case 'products': selectedItem = selectedProduct; break;
            case 'expansions': selectedItem = selectedExpansion; break;
            default:
                break;
        }
        if ((selectedItem && !selectedItem.props.isBought)) {
            const { products, expansions, bought } = this.state
            let isItemInCart = false
            let item = {}
            let array = []
            var product2 = []
            var newBought = []
            var selected = []
            var newPrice = 0

            switch (component) {
                case 'products':
                    item = products.find((productItem) => productItem.props.position === 0)
                    isItemInCart = bought.find((cartItem) => cartItem.props.id === item.props.id);
                    array = products
                    break;
                case 'expansions':
                    item = expansions.find((expansionItem) => expansionItem.props.position === 0)
                    isItemInCart = bought.find((cartItem) => cartItem.props.id === item.props.id);
                    array = expansions
                    break;

                default:
                    break;
            }

            array.forEach(item => {
                var nBought = item.props.position == 0 ? true : item.props.isBought
                if (nBought) {
                    const p2 = (
                        <Product
                            key={item.props.title + item.props.id + '_bought'}
                            img={item.props.img}
                            position={bought.length}
                            title={item.props.title}
                            text={item.props.text}
                            isBought={nBought}
                            onBoughtList={true}
                            id={item.props.id}
                            handleRemove={(e) => this.handleRemove(e, component)}
                        />
                    )
                    if (item.props.position === 0) {
                        newBought = p2
                    }
                }

                const p = (
                    <Product
                        key={item.props.title + item.props.id}
                        img={item.props.img}
                        position={item.props.position}
                        title={item.props.title}
                        text={item.props.text}
                        isBought={nBought}
                        onBoughtList={false}
                        id={item.props.id}
                        handleRemove={(e) => this.handleRemove(e, component)}
                    />
                )

                if (item.props.position === 0) {
                    selected = p
                }

                product2.push(p)
            })


            if (!isItemInCart) {
                // adding product to global cart
                const prevCart = bought.map(p => p.props.id)
                const newCart = [...prevCart, newBought.props.id]

                // Updating global cart state
                setCart(newCart)

                newPrice = database !== null ? this.updatePrice(newCart) : 0

                this.setState((prevState) => ({
                    [component === 'products' ? 'products' : 'expansions']: product2,
                    [component === 'products' ? 'selectedProduct' : 'selectedExpansion']: selected,
                    bought: [...prevState.bought, newBought],
                    price: newPrice
                }))

            }
        }
    }

    handleToCheckout() {
        if (this.state.bought.length > 0) {
            const { cart, navigate } = this.props // cart updater from global Context

            // Use navigate to go to checkout page WITHOUT reloading - necessary to keep app state intact
            // const tid = JSON.stringify(cartIDs).toString().slice(1, -1);
            const tid = cart.join(',');
            // window.location.href = '/checkout?id=' + tid
            navigate(`/checkout?id=${tid}`)
        }
    }

    handleTouchStart = (event) => {
        const touch = event.touches[0];

        this.setState({ touchStartX: touch.clientX });
    };

    handleTouchMove = (event) => {
        if (!this.state.touchStartX) return;

        const touch = event.touches[0];


        this.setState({ touchEndX: touch.clientX });
    };

    handleTouchEnd = () => {
        if (!this.state.touchStartX || !this.state.touchEndX) return;

        const treshold = 70;

        const swipeDistance = this.state.touchEndX - this.state.touchStartX;

        if (swipeDistance > treshold) {
            this.handleLeft();
        } else if (swipeDistance < -treshold) {
            this.handleRight();
        }



        this.setState({
            touchStartX: null,
            touchEndX: null,
        });
    };

    hideAndShowText() {


        this.setState({
            isOpen: !this.state.isOpen
        })
    }

    getProductCards = (productId) => {
        const { cards } = this.props // passed from Main.jsx state
        // console.log('props cards: ', cards)

        var cardList = []

        // filter all cards to match cards from product
        cardList = cards.filter(c => c.product === productId)

        return cardList
    }

    render() {
        const { isOpen, products, expansions, selectedProduct, selectedExpansion, bought } = this.state;
        const open = isOpen ? "open" : "close"
        const text = isOpen ? t('buy_status_text_open') : t('buy_status_text_close')

        // var valExp = expansions.length !== 0 ? (
        //     <div className='buy_expensions_container'>
        //         <div className="buy_select_products_wrapper">
        //             {expansions}
        //         </div>

        //         <div className='buy_select_title_container'>
        //             {expansions.length > 1 &&
        //                 <>
        //                     <div className='buy_select_left buy_select_controls' onClick={(e) => { this.handleLeft(e, 'expansions') }}>
        //                         <i className="bi bi-caret-left-fill"></i>
        //                     </div>
        //                     <div className='buy_select_right buy_select_controls' onClick={(e) => { this.handleRight(e, 'expansions') }} >
        //                         <i className="bi bi-caret-right-fill"></i>
        //                     </div>
        //                 </>
        //             }
        //             <div className='buy_select_title'>
        //                 {selectedExpansion && selectedExpansion.props.title}
        //             </div>
        //         </div>

        //         <div className={'buy_selected_text_container buy_selected_text_container_' + open}>

        //             <div className={'buy_selected_text buy_selected_text_' + open} id='selected_text'>
        //                 {selectedExpansion && selectedExpansion.props.text}
        //             </div>
        //             <button className="button_show" id='selected_btn' onClick={this.hideAndShowText}>{text}</button>
        //         </div>

        //         <div className={('buy_select_button_valider_container')}>
        //             <div
        //                 className={('buy_select_button_valider_text buy_select_button_valider_image button buttonShadows gradientOrangePink') + (selectedExpansion && selectedExpansion.props.isBought ? ' buttonDisabled' : '')}
        //                 onClick={(e) => { this.handleAddToCart(e, 'expansions') }}
        //             >
        //                 <i className="bi bi-cart-plus"></i><span>{t('buy_add_to_cart')}</span>
        //             </div>
        //         </div>
        //     </div>
        // ) : ''


        // const valExpansions = expansions.length > 0 ? (
        //     <div className='buy_expansions_container'>
        //         <h2 className='p-2'>{t('buy_latest_expansions')}</h2>
        //         <CardList className='d-grid' itemsPerPage={3} items={expansions} cards={this.props.cards} onAddToCart={this.addToCart} />
        //         {/* <ResponsiveSlider items={expansions} onAddToCart={this.addToCart} /> */}
        //     </div>
        // ) : ''

        var productCardsBase = []
        var productCardsExp = []

        productCardsBase = selectedProduct !== null && this.getProductCards(selectedProduct.props.id)
        // productCardsExp = selectedExpansion !== null && this.getProductCards(selectedExpansion.props.id)


        // console.log(selectedExpansion)

        var lng = localStorage.getItem('i18nextLng') !== undefined ? localStorage.getItem('i18nextLng').slice(0, 2) : 'en'

        // Product Cards Tooltip
        const popoverBase = (
            <Popover className="buy_product_cards" id="popover-products">
                <Popover.Header as="h3">{t('buy_popover_header')}</Popover.Header>
                <Popover.Body>
                    {t('buy_popover_text')}
                    <div className='buy_product_cardsBox'>
                        {productCardsBase && productCardsBase.map(c => {
                            const img1 = require('../img/pulses/' + c.pulse + '/sample/' + lng + '/' + c.image)
                            return (
                                <AltarCard
                                    key={'p_' + c.image}
                                    title={c.title}
                                    text={''}
                                    image={img1}
                                    alt={c.image}
                                    pulse={c.pulse}
                                />
                            )
                        })}
                    </div>
                </Popover.Body>
            </Popover>
        )

        const popoverExp = (
            <Popover className="buy_product_cards" id="popover-products">
                <Popover.Header as="h3">{t('buy_popover_header')}</Popover.Header>
                <Popover.Body>
                    {t('buy_popover_text')}
                    <div className='buy_product_cardsBox'>
                        {productCardsExp && productCardsExp.map(c => {
                            const img1 = require('../img/pulses/' + c.pulse + '/sample/' + lng + '/' + c.image)
                            return (
                                <AltarCard
                                    key={'p_' + c.image}
                                    title={c.title}
                                    text={''}
                                    image={img1}
                                    alt={c.image}
                                    pulse={c.pulse}
                                />
                            )
                        })}
                    </div>
                </Popover.Body>
            </Popover>
        )

        // getting discounts and compute_price functions from context
        const { database, onComputePrice } = this.props

        var items = []
        var l1 = 0
        var l2 = 0
        var l3 = 0
        var l4 = 0
        var l5 = 0
        var l6 = 0

        if (database != null) {
            items = [1]
            l1 = onComputePrice(items, database)
            items = [1, 2]
            l2 = onComputePrice(items, database)
            items = [1, 2, 3]
            l3 = onComputePrice(items, database)
            items = [1, 2, 3, 4]
            l4 = onComputePrice(items, database)
            items = [1, 2, 3, 4, 5]
            l5 = onComputePrice(items, database)
            items = [1, 2, 3, 4, 5, 6]
            l6 = onComputePrice(items, database)

        }

        const { price } = this.state

        return (
            <div id="purchase" className='buy_container'>
                <div className='buy_info_container gradientOrangePink boxShadows buttonRadius text-dark fs-4 py-5 p-md-5 p-3'>
                        {/* <span className='fw-light'><i className="bi bi-plus-slash-minus px-2"></i>{t('buy_info_pre_title')}</span> */}
                        <h4>{t('buy_info_heading')}</h4>
                            <ul className='text-start'>
                                <li><i className="bi bi-collection-fill mx-2"></i>{t('buy_info_base_1')}</li>
                                <li><i className="bi bi-printer-fill mx-2"></i>{t('buy_info_base_2')}</li>
                                <li><i className="bi bi-stack mx-2"></i>{t('buy_info_base_3')}<small></small></li>
                                <li><i className="bi bi-people-fill mx-2"></i>{t('buy_info_base_4')}</li>
                                <li><i className="bi bi-trophy-fill mx-2"></i>{t('buy_info_base_5')}</li>
                            </ul>
                        {/* <hr /> */}
                </div>
                {/* Discount Container */}
                <div className='buy_rabais_container buy_rabais_image'>
                    <div className='buy_rabais_text_container'>
                        <p>
                            {t('buy_rabais_individuel')} {l1} CHF
                        </p>
                        <p>
                            {t('buy_rabais_duo')} {l2}  CHF
                        </p>
                        <p>
                            {t('buy_rabais_3')} {l3}  CHF
                        </p>
                        <p>
                            {t('buy_rabais_4')} {l4}  CHF
                        </p>
                        <p>
                            {t('buy_rabais_5')} {l5}  CHF
                        </p>
                        <p>
                            {t('buy_rabais_6')} {l6}  CHF
                        </p>
                    </div>
                </div>

                {/* Base Products Carousel */}
                <div className='buy_select_container' onTouchStart={this.handleTouchStart} onTouchMove={this.handleTouchMove} onTouchEnd={this.handleTouchEnd}>
                    <div className="buy_select_products_wrapper">
                        {products}
                    </div>

                    <div className='buy_select_title_container'>
                        <div className='buy_select_left buy_select_controls' onClick={(e) => { this.handleLeft(e, 'products') }}>
                            <i className="bi bi-caret-left-fill"></i>
                        </div>
                        <div className='buy_select_right buy_select_controls' onClick={(e) => { this.handleRight(e, 'products') }} >
                            <i className="bi bi-caret-right-fill"></i>
                        </div>
                        <div className='buy_select_title'>
                            {selectedProduct && (
                                <>
                                    {selectedProduct.props.title}
                                    <OverlayTrigger trigger={['click', 'focus']} placement="top" overlay={popoverBase} rootClose>
                                        <span className='text-decoration-underline'><i className="bi bi-question-circle mx-2"></i><span className='text-decoration-none'>{t('buy_product_cards')}</span></span>
                                    </OverlayTrigger>
                                </>
                            )}
                        </div>
                    </div>

                    <div className={'buy_selected_text_container buy_selected_text_container_' + open}>

                        <div className={'buy_selected_text buy_selected_text_' + open} id='selected_text'>
                            {selectedProduct && selectedProduct.props.text}
                        </div>
                        <button className="button_show" id='selected_btn' onClick={this.hideAndShowText}>{text}</button>
                    </div>

                    <div className={('buy_select_button_valider_container')}>
                        <div
                            className={('buy_select_button_valider_text buy_select_button_valider_image button buttonShadows gradientOrangePink') + (selectedProduct && selectedProduct.props.isBought ? ' buttonDisabled' : '')}
                            onClick={(e) => { this.handleAddToCart('products') }}
                        >
                            <i className="bi bi-cart-plus"></i><span>{t('buy_add_to_cart')}</span>
                        </div>
                    </div>
                </div>

                {/* Expansion Products Carousel */}
                {expansions.length > 0 && (
                <div className='buy_expansions_container' onTouchStart={this.handleTouchStart} onTouchMove={this.handleTouchMove} onTouchEnd={this.handleTouchEnd}>
                    <div className="buy_select_products_wrapper">
                        {expansions}
                    </div>

                    <div className='buy_select_title_container'>
                        <div className='buy_select_left buy_select_controls' onClick={(e) => { this.handleLeft(e, 'expansions') }}>
                            <i className="bi bi-caret-left-fill"></i>
                        </div>
                        <div className='buy_select_right buy_select_controls' onClick={(e) => { this.handleRight(e, 'expansions') }} >
                            <i className="bi bi-caret-right-fill"></i>
                        </div>
                        <div className='buy_select_title'>
                            {selectedExpansion && (
                                <>
                                    {selectedExpansion.props.title}
                                    <OverlayTrigger trigger={['click', 'focus']} placement="top" overlay={popoverExp} rootClose>
                                        <span className='text-decoration-underline'><i className="bi bi-question-circle mx-2"></i><span className='text-decoration-none'>{t('buy_product_cards')}</span></span>
                                    </OverlayTrigger>
                                </>
                            )}
                        </div>
                    </div>

                    <div className={'buy_selected_text_container buy_selected_text_container_' + open}>

                        <div className={'buy_selected_text buy_selected_text_' + open} id='selected_text'>
                            {selectedExpansion && selectedExpansion.props.text}
                        </div>
                        <button className="button_show" id='selected_btn' onClick={this.hideAndShowText}>{text}</button>
                    </div>

                    <div className={('buy_select_button_valider_container')}>
                        <div
                            className={('buy_select_button_valider_text buy_select_button_valider_image button buttonShadows gradientOrangePink') + (selectedProduct && selectedProduct.props.isBought ? ' buttonDisabled' : '')}
                            onClick={(e) => { this.handleAddToCart('products') }}
                        >
                            <i className="bi bi-cart-plus"></i><span>{t('buy_add_to_cart')}</span>
                        </div>
                    </div>
                </div>
                )}

                {/* Cart Container */}
                <div className='buy_list_buy_container'>
                    <div className='buy_list_background_container'>
                        {/* Added-to-Cart list */}
                        <div className='d-flex flex-wrap buy_list_bought'>{bought}</div>
                        {/* <Cart items={this.state.cart} onRemove={this.removeFromCart}/> */}
                        <div className='buy_list_background_text'>
                            {price != 0 ?
                                t('buy_total_price') + ':' + price + 'CHF'
                                :
                                t('buy_empty_cart')
                            }
                        </div>
                    </div>
                    <div
                        className='buy_list_button_valider_container'
                    >
                        <div
                            className='buy_list_button_valider_text button buttonShadows gradientOrangePink buy_list_button_valider_image'
                            onClick={this.handleToCheckout}
                        >
                            {t('buy_to_checkout')}
                        </div>
                    </div>
                </div>

            </div>
        )
    }

}

export default withDiscount(Buy)

