import React from 'react';
import Image from 'react-bootstrap/Image'
import YouTube from 'react-youtube';


import kokabil from '../img/melgraphics/kokabile_mentor_of_the_hidden_path_recadrage.png';
import oyama from '../img/melgraphics/o_yama_king_of_whispers_recadrage.png';

import { t } from 'i18next';
import { withTranslation } from 'react-i18next';


class Description extends React.Component {
    /*
    <Image className='description_right' src={right} alt="" />
    */
    render() {

        return (
            <div className="description_grid">
                <div className='description_text'>
                    <p className='description_title mt-3'>{ t('description_title') }</p>
                    <p className='subdescription_title'>{ t('description_subtitle') }</p>
                    <YouTube videoId="YgCxcoG5K64" className='video_container mb-3' />
                </div>
                <div className="description_left">
                    <Image className='description_image' src={kokabil} alt="" />
                </div >

                <div className="description_right">
                    <Image className='description_image' src={oyama} alt="" />
                </div >
            </div >
        )
    }

}

export default withTranslation()(Description)

