import React from 'react'

import { useNavigate } from 'react-router-dom';
import { useDiscount } from '../../context/DiscountContext'

// import { withTranslation } from 'react-i18next'

const withDiscount = Component => props => {
    const { cart, setCart, compute_price, database } = useDiscount();
    const navigate = useNavigate()

    return <Component {...props} navigate={navigate} onComputePrice={compute_price} database={database} setCart={setCart} cart={cart} />;
  };

export default withDiscount