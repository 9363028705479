import React from "react";
import Image from "react-bootstrap/Image";
import { Helmet } from "react-helmet";

import Menu from "./menu";
import ReturnButton from "./returnButton";

// Translations Module
import i18n, { t } from "i18next";
import { withTranslation } from "react-i18next";
import Buybutton from "./buybutton";

class FreeDownload extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pulses: [],
            selectedPulses: [],
            curr_edit_state: 0,
            nEmail: "",
            items: [],
            loading: false,
            pseudo: null,
            password: null,
            password2: null,
        };

        var items = [];
    }

    downloadPDF = async () => {
        // using Java Script method to get PDF file

        if (this.state.selectedPulses.length !== 0) {
            this.setState({
                loading: true,
            });

            var statusLoggedIn = localStorage.getItem("token") != null ? true : false;
            var lng = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').slice(0, 2) : 'en'
            // console.log("User state is: " + statusLoggedIn);

            fetch("/api.php", {
                method: "POST",
                body: JSON.stringify({
                    action: "download",
                    items: this.state.selectedPulses,
                    email: "",
                    pseudo: "",
                    lang: lng,
                    isLoggedIn: statusLoggedIn,
                }),
            })
                .then(response => {
                    if (!response.ok) {
                        console.log("Response status:", response.status);
                        console.log("Response body:", response.type());
                        throw new Error("Network response was not ok");
                    }
                    return response.blob();
                })
                .then(blob => {
                    // console.log("PDF was successfully created!")
                    var file = window.URL.createObjectURL(blob);
                    window.location.assign(file);
                })
                .catch(error => {
                    console.error("There has been a problem with your fetch operation:", error);
                })
                .finally(() => {
                    this.setState({
                        loading: false,
                    });
                });
        }
    };

    onPulseClick = id => e => {
        var select = this.state.selectedPulses;
        if (e.target.checked) {
            select.push(id);
        } else {
            const index = select.indexOf(id);
            if (index > -1) {
                // only splice array when item is found
                select.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
        this.setState({
            selectedPulses: select,
        });
        console.log(this.state.selectedPulses);
    };

    render() {
        const { loading } = this.state;
        var pulses = [];
        var img1 = "";
        const pulses_t = t("pulsesInfos", { ns: "cards", returnObjects: true });
        if (Object.values(pulses_t)[0]["text"] !== undefined) {
            Object.values(pulses_t).forEach(pulseitem => {
                try {
                    img1 = require("../img/pulses/" + pulseitem.image);
                } catch (error) {
                    console.log("pulse image could not be loaded");
                }

                var pulseClassName = pulseitem.name;
                // tranforming string to lowercase in case it isnt
                pulseClassName = pulseClassName.toString().toLowerCase();
                const pulse = (
                    <div className={pulseClassName}>
                        <label htmlFor={pulseClassName + "_btn"}>
                            <Image
                                className={pulseClassName + "_image"}
                                src={img1}
                                alt={pulseitem.image}
                                title={pulseitem.title}
                            />
                            <div className={pulseClassName + "_btn_container d-flex justify-content-end align-items-center"}>
                                <span className="w-100 text-light text-center">{pulseitem.title}</span>
                                <input
                                    type="checkbox"
                                    className={pulseClassName + "_btn flex-shrink-1"}
                                    id={pulseClassName + "_btn"}
                                    name={pulseClassName + "_btn"}
                                    value={pulseClassName + "_btn"}
                                    onChange={this.onPulseClick(pulseitem.id)}
                                    title={t("account_download_checkbox", { pulse: pulseitem.title })} // variables can be added in the middle of translations strings and be specified at render time like here
                                />
                            </div>
                        </label>
                    </div>
                );
                pulses.push(pulse);
            });
        }

        var loadingDiv = null;
        var downloadDiv = null;

        if (loading) {
            loadingDiv = <div className="lds-dual-ring"></div>;
            downloadDiv = t("account_download_inprogress");
        } else {
            loadingDiv = <div> </div>;
            downloadDiv = (
                <span>
                    <i class="bi bi-download"></i>
                    {t("account_download")}
                </span>
            );
        }

        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{t("page_download")} - Altar</title>
                </Helmet>
                <Menu />
                <div className="grid download_grid">
                    <ReturnButton />
                    <div className="selection_container grid_one_col">
                        <div className="pulse_grid boxShadows">{pulses}</div>

                        <div className="download_container">
                            <p className="textShadows">{t("account_download_files")}</p>
                            <div className="download_txt gradientOrange buttonShadows" onClick={this.downloadPDF}>
                                {downloadDiv}
                            </div>
                            {loadingDiv}
                            <p className="textShadows" style={{ color: "white" }}>
                                {t("account_download_fail")}
                            </p>
                            <p className="textShadows" style={{ color: "white" }}>
                                {t("account_download_message")}
                            </p>
                        </div>
                    </div>
                    <div class="download_cta gradientOrangePink buttonRadius py-5 p-md-5">
                        <span><i className="bi bi-plus-slash-minus px-2"></i>{t('download_cta_pre_title')}</span>
                        <h3>{t('download_cta_what_you_get')}</h3>
                        <table>
                            <tr>
                                <th className="label"></th>
                                <th className="product_free">{t('download_cta_freeversion')}</th>
                                <th className="product_paid">{t('download_cta_paidversion')}</th>
                            </tr>
                            <tr>
                                <td className="label">{t('download_cta_tablerow1')}</td>
                                <td className="product_free text-center"><i className="bi bi-check-lg download_checkicon"></i></td>
                                <td className="product_paid text-center"><i className="bi bi-check-lg download_checkicon"></i></td>
                            </tr>
                            <tr>
                                <td className="label">{t('download_cta_tablerow2')}</td>
                                <td className="product_free text-center"><i className="bi bi-x-lg download_crossicon"></i></td>
                                <td className="product_paid text-center"><i className="bi bi-check-lg download_checkicon"></i></td>
                            </tr>
                            <tr>
                                <td className="label">{t('download_cta_tablerow3')}</td>
                                <td className="product_free text-center"><i className="bi bi-x-lg download_crossicon"></i></td>
                                <td className="product_paid text-center"><i className="bi bi-check-lg download_checkicon"></i></td>
                            </tr>
                            <tr>
                                <td className="label">{t('download_cta_tablerow4')}</td>
                                <td className="product_free text-center"><i className="bi bi-x-lg download_crossicon"></i></td>
                                <td className="product_paid text-center"><i className="bi bi-check-lg download_checkicon"></i></td>
                            </tr>
                        </table>
                        <hr/>
                        <Buybutton />
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation(['cards', 'translation'])(FreeDownload);
