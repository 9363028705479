import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

import ReturnButton from "./returnButton";
import Product from "./product";
import ImageTruth from '../img/pulses/truth.png'
import ImageLies from '../img/pulses/lies.png'
import ImageOrder from '../img/pulses/order.png'
import ImageChaos from '../img/pulses/chaos.png'
import ImageLife from '../img/pulses/life.png'
import ImageDeath from '../img/pulses/death.png'

// Translations Module
import { t } from 'i18next';
import { withTranslation } from "react-i18next";

// Importing custom Hook to use Discount functions
import { useDiscount } from '../context/DiscountContext';

function CheckoutForm({ items }) {
  // Stripe Hooks
  const stripe = useStripe();
  const elements = useElements();

  // URL Params - React Router
  const [searchParams] = useSearchParams();

  // Components State
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const cartString = searchParams.get("id")
  const newCart = cartString.split(',').map(id => parseInt(id, 10))

  // Global state and functions from Context 
  const { cart, setCart, compute_price, database } = useDiscount()


  // Stripe useEffect
  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage(t('checkoutForm_success'));
          break;
        case "processing":
          setMessage(t('checkoutForm_processing'));
          break;
        case "requires_payment_method":
          setMessage(t('checkoutForm_method_required'));
          break;
        default:
          setMessage(t('checkoutForm_fail'));
          break;
      }
    });
  }, [stripe]);

  // Refresh Cart/price when URL changes
  useEffect(() => {
    setCart(newCart)
    return () => {
    }
  }, [])


  const handleCheckboxChange = (event) => {
    setIsTermsAccepted(event.target.checked);
    if (message !== null || message !== '') {
      setMessage(null)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (!isTermsAccepted) {
      setMessage(t('checkoutForm_TOS_required'))
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "https://altarcg.com/validation",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage(error.type);
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  // Drawing Design
  var product = []
  items.forEach(p => {
    // UPDATED CODE for any product or expansion - switch could be removed.

    // const p = <Product
    //   key={p.id}
    //   img={p.image}
    //   onPaiement={true}
    //   class={"checkout_buy_" + p.pulse}
    //   title={p.title}
    // />
    // product.push(p)


    switch (p.id) {
      case "1":
        var p2 = <Product
          img={ImageTruth}
          onPaiement={true}
          class={"checkout_buy_truth"}
        />
        product.push(p2)
        break;
      case "2":
        var p2 = <Product
          img={ImageLies}
          id={2}
          onPaiement={true}
          class={"checkout_buy_lies"}
        />
        product.push(p2)
        break;
      case "3":
        var p2 = <Product
          img={ImageOrder}
          id={2}
          onPaiement={true}
          class={"checkout_buy_order"}
        />
        product.push(p2)
        break;
      case "4":
        var p2 = <Product
          img={ImageChaos}
          id={2}
          onPaiement={true}
          class={"checkout_buy_chaos"}
        />
        product.push(p2)
        break;
      case "5":
        var p2 = <Product
          img={ImageLife}
          id={2}
          onPaiement={true}
          class={"checkout_buy_life"}
        />
        product.push(p2)
        break;
      case "6":
        var p2 = <Product
          img={ImageDeath}
          id={2}
          onPaiement={true}
          class={"checkout_buy_death"}
        />
        product.push(p2)
        break;
    }
  }

  )

  var price = 0

  price = compute_price(cart, database)

  if (items.length % 6 == 0) {

  } else if (items.length % 2 == 0) {

  }

  return (
    <>
      <ReturnButton customClass={"checkout_bouton_retour"} />
      <div className="checkout_payment_container">
        <form id="payment-form" onSubmit={handleSubmit} className="checkout_payement_form">
          <LinkAuthenticationElement
            id="link-authentication-element"
            onChange={(e) => setEmail(e.target.value)}
            className="checkout_email"
          />
          <PaymentElement id="payment-element" options={paymentElementOptions} />
          <label className="text-light">
            <input
              type="checkbox"
              checked={isTermsAccepted}
              onChange={handleCheckboxChange}
            />
            {t('checkoutForm_TOS')} <a href="/terms-of-use">{t('checkoutForm_TOS_link')}</a>
          </label>
          <button disabled={isLoading || !stripe || !elements} id="submit" className="checkout_bouton_submit button gradientOrange buttonRadius">
            <span id="button-text">
              {isLoading ? <div className="spinner" id="spinner"></div> : t('checkoutForm_pay_now')}
            </span>
          </button>
          {/* Show any error or success messages */}
          {message && <div id="payment-message" className="text-warning mb-3">{message}</div>}
        </form>
        <div className="checkout_pulses_container">
          {product}
          <div className="checkout_price">
            {t('buy_total_price')} : {price} CHF
          </div>
        </div>
      </div>
    </>
  );
}

export default withTranslation()(CheckoutForm)