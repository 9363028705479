import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./checkoutForm";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_live_51GuzXTDsUJ335a3XB79QBhhsoUPytwAe3raRpzbaCQjKFZZXgELyZEiBEtzgCIWzHSLWowqvF0EVGyadWwYuthMO00yu84vEex");
export default function App() {
  const [clientSecret, setClientSecret] = useState("");
  const queryParameters = new URLSearchParams(window.location.search)
  const text = queryParameters.get("topText")
  const id =  queryParameters.get("id")


  var items = []

  if(id != null){
    // do not allow duplicate ids
    const test = new Set(id.split(","))
    test.forEach(
      elem => items.push({"id":elem})
    )
  }
  
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("/create.php", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          items: items, 
          pseudo: text
        }),
      })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };



  return (
    <div className="Checkout">
      
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm 
            items = {items}
          />
        </Elements>
      )}
    </div>
  );


}