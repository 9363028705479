/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the cards
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */
import React from "react";
import { Outlet } from "react-router-dom";

import Menu from '../components/menu';
import BackToTopButton from '../components/backToTop';

const Layout = () => {
  return (
    <>
      <Menu />
      <BackToTopButton />
      <Outlet />
    </>
  );
};

export default Layout;