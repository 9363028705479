
/**
 * Altar - by Abalance
 *
 * This file handles the rendering of the cards
 *
 * @author Valentin Schneeberger
 * @since 28.12.2022
 */

import React from 'react';
import Main from "../components/main"
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';


export default function Home() {
    const { t } = useTranslation()
    const navigate = useNavigate()
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('page_home')} - Altar</title>
            </Helmet>
            <Main navigate={navigate} />
        </div>
    );
}