
import React from 'react';

import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import menuAbalance from '../img/melgraphics/menu_ablance.png'
import menuAltar from '../img/melgraphics/menu_altar.png'
import menuLogin from '../img/melgraphics/mobile_login.png'
import menuMonCompte from '../img/melgraphics/mobile_compte.png'

import Login from './login';
// import Register from './register'

// Internationalization module
import LanguageSelector from './languageSelector';
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';

class Menu extends React.Component {
    constructor(props) {
        super(props)
        this.onLogin = this.onLogin.bind(this)
        this.onLoginExit = this.onLoginExit.bind(this)
        this.onRegister = this.onRegister.bind(this)
        this.onRegisterExit = this.onRegisterExit.bind(this)
        this.state = {
            login: 'login_not',
            register: 'register_not'
        }
    }

    onLogin() {
        this.setState({
            login: 'login',
            register: 'register_not'
        })
    }

    onLoginExit() {
        this.setState({
            login: 'login_not',
        })
    }

    onRegister(){
        this.setState({
            login: 'login_not',
            register: 'register'
        })
    }

    onRegisterExit(){
        this.setState({
            register: 'register_not'
        })
    }

    onAbalance(){
        window.location = 'https://www.abalance.ch';
    }
    
    render() {
        var pathname = window.location.pathname
        var menu_moncompte_class = 'menu_moncompte_display'
        var menu_login_register_class = 'menu_login_register_not_display'
        if(localStorage.getItem('token') == null){
            menu_moncompte_class = 'menu_moncompte_not_display'
            menu_login_register_class = 'menu_login_register_display'
        }
        
        return <div className='topMenu'>
            <Login
                display={this.state.login}
                onExit={this.onLoginExit}
            />
            
            <div className='menu_left px-sm-3'>
                <Link to="/">
                    <Image
                        src={menuAltar}
                    />
                </Link>
            </div>
            <Link to="" className='menu_right px-sm-3'>
                <Image
                    src={menuAbalance}
                    onClick={this.onAbalance}
                />
            </Link>
            <LanguageSelector/>

            <Link onClick={this.onLogin} className={'menu_login '+ menu_login_register_class}>
                {t('menu_login_text')}
            </Link>

            <Link onClick={this.onLogin}>
                <Image
                    className={'icon_login '+ menu_login_register_class}
                    src={menuLogin}
                    alt="menu_login.jpg"
                />
            </Link>

            <Link to="/account">
                <Image
                    className={'icon_moncompte ' + menu_moncompte_class }
                    src={menuMonCompte}
                    alt="mobile_moncompte.jpg"
                />
            </Link>
            <Link to="/account" className={'menu_moncompte ' + menu_moncompte_class }>
                {t('menu_login_account')}
            </Link>
        </div>
    }

}

export default withTranslation()(Menu)





