
import React from 'react';
import Image from 'react-bootstrap/Image'
import { Helmet } from 'react-helmet';

import ReturnButton from './returnButton';
import ImgGenerator from './ImgGenerator';
import ImageEdit from '../img/melgraphics/edit.png';
import ImageTick from '../img/melgraphics/tick.png';

// Translations Module
import { t } from 'i18next';
import { withTranslation } from 'react-i18next';

class Account extends React.Component {

    constructor(props) {
        super(props)
        if (localStorage.getItem('token') == null) {
            window.location.href = '/'
        }

        this.state = {
            pulses: [],
            selectedPulses: [],
            editState: {
                none: 0,
                editingMail: 1,
                editingPassword: 2
            },
            curr_edit_state: 0,
            nEmail: "",
            items: [],
            loading: false,
            pseudo: null,
            password: null,
            password2: null,
        };

        var items = []

        try {
            fetch('/api.php', {
                method: 'POST',
                body: JSON.stringify({
                    action: 'getItems',
                    status: localStorage.getItem('token'),
                    email: localStorage.getItem('email'),
                }),
            })
                .then((respose) => {
                    if (respose.ok) {
                        return respose.json()
                    }
                    // throw new Error('error')
                })
                .then((data) => {
                    if (data) {

                        if (data.items) {
                            console.log("Get items ffrom server")
                            items = data.items
                            console.log(items)
                            this.setState({
                                items: items
                            })
                        } else {
                            //set error
                        }
                    }
                })
        } catch (error) {

            console.log(error.message)
        }
        
    }

    onButtonClick = () => {
        
        const lng = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').slice(0, 2) : 'en'

        if (this.state.selectedPulses.length !== 0) {

            this.setState({
                loading: true
            })

            try {
                fetch('/api.php', {
                    method: 'POST',
                    body: JSON.stringify({
                        action: 'download',
                        status: localStorage.getItem('token'),
                        email: localStorage.getItem('email'),
                        isLoggedIn: localStorage.getItem('token') !== null ? true : false,
                        pseudo: this.state.pseudo,
                        items: this.state.selectedPulses,
                        lang: lng,
                    }),
                    responseType: 'blob', // important
                })
                    .then(respose => {
                        respose.blob().then(blob => {
                            if (respose.ok) {


                                var file = window.URL.createObjectURL(blob);
                                window.location.assign(file);
                                /*
                                
                                console.log(respose)
                                // Creating new object of PDF file
                                const fileURL = window.URL.createObjectURL(blob);
                                // Setting various property values
                                let alink = document.createElement('a');
                                alink.href = fileURL;
                                alink.download = 'product.pdf';
                                alink.click();
    
                                */

                                this.setState({
                                    loading: false
                                })
                            }

                        })
                    })
            } catch (error) {
                console.log(error.message)
            }
        }



        /*
        fetch('/tmp/players/' + this.props.email + '/product.pdf').then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'product.pdf';
                alink.click();
            })
        })
    
        */
    }

    onPulseClick = id => (e) => {
        var select = this.state.selectedPulses
        if (e.target.checked) {
            select.push(id)
        } else {
            const index = select.indexOf(id);
            if (index > -1) { // only splice array when item is found
                select.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
        this.setState({
            selectedPulses: select
        })
        console.log(this.state.pseudo)
        console.log('SelectedPulses: ' + this.state.selectedPulses)
    }

    onChangeEmail = (e) => {
        this.setState({
            nEmail: e.target.value
        })

    }

    onChangePseudo = (e) => {
      console.clear()
        // console.log(e.target.value)
        this.setState({
            pseudo: e.target.value
        })
    }

    onChangePassword = (e) => {
        this.setState({
            password: e.target.value
        })
    }

    onChangePassword2 = (e) => {
        this.setState({
            password2: e.target.value
        })
    }

    editEmail = () => {
        this.setState({
            curr_edit_state: this.state.editState.editingMail
        })
    }

    editPassword = () => {
        this.setState({
            curr_edit_state: this.state.editState.editingPassword
        })
    }

    editNone = () => {
        this.setState({
            curr_edit_state: this.state.editState.none
        })
    }

    validateMail = () => {
        // do crazy stuff from database
        if (this.state.nEmail !== "") {
            try {
                fetch('/api.php', {
                    method: 'POST',
                    body: JSON.stringify({
                        action: 'changeEmail',
                        status: localStorage.getItem('token'),
                        email: localStorage.getItem('email'),
                        newEmail: this.state.nEmail
                    }),
                })
                    .then(respose => {

                        if (respose.ok) {
                        }
                    })
                    .then((data) => {
                        if (data.status) {
                            localStorage.setItem('email', data.email)
                        } else {
                            //set error
                        }
                    })
            } catch (error) {
                console.log(error.message)
            }
        }
        this.editNone();
    }

    validatePass = () => {
        // do another crazy stuff from database
        if (this.state.password !== "" && this.state.password === this.state.password2) {
            try {
                fetch('/api.php', {
                    method: 'POST',
                    body: JSON.stringify({
                        action: 'changePassword',
                        status: localStorage.getItem('token'),
                        email: localStorage.getItem('email'),
                        password: this.state.password,
                        password2: this.state.password2
                    }),
                })
                    .then(respose => {
                        if (respose.ok) {

                        }
                    })
            } catch (error) {
                console.log(error.message)
            }
        }
        this.editNone();
    }

    render() {

        console.log("New render")
        console.log(this.state)
        var editFields = [];
        var uneditedMail = [
            <div className="unedited_mail_container">
                <div className="unedited_mail">
                    <label className="email-label">{t('login_register_email')} :</label>
                    <span id="email-display">example@example.com</span>
                </div>
            </div>,
            <Image className='edit_mail_btn' src={ImageEdit} onClick={this.editEmail} />
        ];

        var uneditedPassword = [
            <div className="unedited_pass_container">
                <div className="unedited_pass">
                    <label className="passsword-label">{t('login_register_pwd')}</label>
                </div>
            </div>,
            <Image className='edit_pass_btn' src={ImageEdit} onClick={this.editPassword} />
        ];

        switch (this.state.curr_edit_state) {
            case this.state.editState.editingMail:
                editFields.push(
                    <div className="edit_mail_container">
                        <input className='mail_input'
                            type="email"
                            id="edit_mail"
                            name="edit_mail"
                            placeholder={t('login_register_email_addr')}
                            pattern="[^ @]*@[^ @]*"
                            required
                            autoFocus
                            onChange={this.onChangeEmail}
                        />
                    </div>,
                    <Image className='validate_mail_btn' src={ImageTick} onClick={this.validateMail} />
                );
                editFields = editFields.concat(uneditedPassword);
                break;

            case this.state.editState.editingPassword:
                editFields.push(
                    <div className="edit_pass_container">
                        <input className='password_input'
                            type="password"
                            id="pass"
                            name="pass"
                            placeholder={t('login_register_pwd')}
                            autoFocus
                            onChange={this.onChangePassword}
                        />
                    </div>,

                    <div className="edit_confirm_pass_container">
                        <input className='confirm_password_input'
                            type="password"
                            id="confirm_pass"
                            name="confirm_pass"
                            placeholder={t('login_register_pwd_confirm')}
                            onChange={this.onChangePassword2}
                        />
                    </div>,

                    <Image className='validate_pass_btn' src={ImageTick} onClick={this.validatePass} />
                );
                editFields = editFields.concat(uneditedMail);
                break;

            default:    // none
                editFields = uneditedMail.concat(uneditedPassword);
                break;
        }

        var pulses = []
        var img1 = ''
        const pulses_t = t('pulsesInfos', { ns: 'cards', returnObjects: true })
        //const pulses_t = t('pulsesInfos', {returnObjects: true })
        if (Object.values(pulses_t)[0]['text'] !== undefined) {
            console.log("We made it there")

            Object.values(pulses_t).forEach(pulseitem => {

                try {
                    img1 = require('../img/pulses/' + pulseitem.image);
                } catch (error) {
                    console.log('pulse image could not be loaded')
                }

                var pulseClassName = pulseitem.name
                // tranforming string to lowercase in case it isnt
                pulseClassName = pulseClassName.toString().toLowerCase()
                
                if (this.state.items.find( item => item === pulseitem.id)) {
                    const pulse = (
                        <div className={pulseClassName}>
                            <Image className={pulseClassName + "_image"} src={img1} alt={pulseitem.image} title={pulseitem.title} />
                            <div className={pulseClassName + "_btn_container"}>
                                <input
                                    type="checkbox"
                                    className={pulseClassName + "_btn"}
                                    id={pulseClassName + "_btn"}
                                    name={pulseClassName + "_btn"}
                                    value={pulseClassName + "_btn"}
                                    onChange={this.onPulseClick(pulseitem.id)}
                                    title={t('account_download_checkbox', { pulse: pulseitem.title })} // variables can be added in the middle of translations strings and be specified at render time like here
                                />
                            </div>
                        </div>
                    )
                    pulses.push(pulse)
                }

            })
        } else{
            console.log(pulses_t)
        }

        console.log(pulses)

        var loading = null
        var downloadDiv = null

        if (this.state.loading) {
            loading = <div className="lds-dual-ring"> </div>
            downloadDiv = t('account_download_inprogress')

        } else {
            loading = <div> </div>
            downloadDiv = <span><i class="bi bi-download"></i>{t('account_download')}</span>
        }


        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{t('page_account')} - Altar</title>
                </Helmet>
                <div className="grid">
                    <ReturnButton customClass="account_bouton_retour" />
                    <div className="img_gen">
                        <ImgGenerator
                            onChangePseudo={this.onChangePseudo}
                        />
                    </div>

                    <div className="edit_infos_container gradientPurplePink boxShadows">
                        <div className="edit_inputs_container">
                            {editFields}
                        </div>
                    </div>

                    <div className="selection_container">
                        <div className="pulse_grid boxShadows">
                            {pulses}
                        </div>

                        <div className="download_container">
                            <p className="textShadows">
                                {t('account_download_files')}
                            </p>
                            <div onClick={this.onButtonClick} className="download_txt gradientOrange buttonShadows">{ downloadDiv }</div>
                            {loading}
                            <p style={{ "color": "white" }}>
                                {t('account_download_fail')}
                            </p>
                            <p style={{ "color": "white" }}>
                                {t('account_download_message')}
                            </p>
                        </div>
                    </div>
                </div >
            </>
        )
    }
}

export default withTranslation(['cards', 'translation'])(Account)
