import React, { useState, useEffect } from 'react';

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Detect scroll position and toggle visibility
  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Add scroll event listener on component mount
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    const scrollStep = Math.PI / (500 / 15);
    const cosParameter = window.scrollY / 2;
  
    let scrollCount = 0;
    let scrollMargin;
  
    const scrollInterval = setInterval(() => {
      if (window.scrollY !== 0) {
        scrollCount += 1;
        scrollMargin = cosParameter - cosParameter * Math.cos(scrollCount * scrollStep);
        window.scrollTo(0, (window.scrollY - scrollMargin));
      } else {
        clearInterval(scrollInterval);
      }
    }, 15);
  };
  

  return (
    <div className={`back-to-top-button ${isVisible ? 'visible' : 'hidden'}`} onClick={scrollToTop}>
      <h1><i className="bi bi-arrow-up-circle-fill h1"></i></h1>
    </div>
  );
};

export default BackToTopButton;
